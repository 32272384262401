
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Market } from "@/models/market/Market";
import { MarketRequest } from "@/models/request/MarketRequest";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import DxButton from "devextreme-vue/button";
import DxTabPanel from "devextreme-vue/tab-panel";
import DxAccordion from "devextreme-vue/accordion";
import DxLookup from "devextreme-vue/lookup";
import { RequestMutations } from "@/store/modules/request/mutations";
import { LookupEntity } from "@/models/LookupEntity";
import DxValidator, { DxCustomRule, DxAdapter } from "devextreme-vue/validator";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { User } from "@/models/User";
import DxList from "devextreme-vue/list";
import { UserActions } from "@/store/modules/user/actions";
import { UpdateEntity } from "@/models/UpdateEntity";
import { DxTagBox } from "devextreme-vue/tag-box";
import { getUserTitle, isUserInRoles } from "@/services/userUtils";
import MarketSelector from "../MarketSelector";
import BaseButton from "@/components/BaseButton";

@Component({
    components: {
        DxButton,
        DxAccordion,
        DxTabPanel,
        DxLookup,
        DxValidator,
        DxAdapter,
        DxCustomRule,
        DxList,
        DxTagBox,
        MarketSelector,
        BaseButton
    },
    computed: {
        ...requestModule.mapGetters({
            marketRequests: RequestGetters.Markets
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...userModule.mapActions({
            loadUsersByMarket: UserActions.LoadUsersByMarket
        }),
        ...requestModule.mapMutations({
            addNewMarketRequest: RequestMutations.AddNewMarket,
            removeMarketRequest: RequestMutations.RemoveMarket,
            updateMarketRequest: RequestMutations.UpdateMarket
        })
    }
})
export default class RequestMarketsForm extends Vue {
    private readonly marketRequests!: MarketRequest[];
    private readonly users!: User[];

    private readonly loadUsersByMarket!: (marketId: number) => Promise<void>;
    private readonly addNewMarketRequest!: (payload: Market) => void;
    private readonly removeMarketRequest!: (payload: MarketRequest) => void;
    private readonly updateMarketRequest!: (
        payload: UpdateEntity<MarketRequest>
    ) => void;

    protected popupVisible = false;
    private selectedItem: Market | null = null;

    get selectedMarketRequest(): MarketRequest | null {
        if (this.selectedItem) {
            return (
                this.marketRequests.find(
                    (r) => r.marketId === this.selectedItem?.marketId
                ) ?? null
            );
        }
        return null;
    }

    get selectedMarketId(): number {
        return this.selectedMarketRequest?.marketId ?? -1;
    }

    get selectedMarkets(): Market[] {
        return this.marketRequests.map((r) => r.market);
    }

    get toMarketUsers(): LookupEntity[] {
        return this.users
            .filter((u) => isUserInRoles(["MSU", "MU"], u))
            .map((user) => ({
                id: user.id,
                name: getUserTitle(user, this.selectedMarketId)
            }));
    }

    get ccMarketUsers(): LookupEntity[] {
        return this.users
            .filter((u) => isUserInRoles(["MSU", "MU", "MV"], u))
            .map((user) => ({
                id: user.id,
                name: getUserTitle(user, this.selectedMarketId)
            }));
    }

    get toUserIds(): number[] {
        return this.selectedMarketRequest?.usersTo.map((u) => u.id) ?? [];
    }

    set toUserIds(value: number[]) {
        if (this.selectedMarketRequest) {
            const newUsers = this.users.filter((u) => value.includes(u.id));
            this.updateMarketRequest({
                key: this.selectedMarketRequest,
                fields: {
                    usersTo: newUsers
                }
            });
        }
    }

    get ccUserIds(): number[] {
        return this.selectedMarketRequest?.usersCc.map((u) => u.id) ?? [];
    }

    set ccUserIds(value: number[]) {
        if (this.selectedMarketRequest) {
            const newUsers = this.users.filter((u) => value.includes(u.id));
            this.updateMarketRequest({
                key: this.selectedMarketRequest,
                fields: {
                    usersCc: newUsers
                }
            });
        }
    }

    setMarkets(markets: Market[]): void {
        const newMarkets = markets.filter(
            (m) =>
                !this.selectedMarkets.find((sm) => sm.marketId === m.marketId)
        );
        const removedMarkets = this.selectedMarkets.filter(
            (m) => !markets.find((sm) => sm.marketId === m.marketId)
        );
        newMarkets.forEach((m) => {
            this.addNewMarketRequest(m);
        });
        removedMarkets.forEach((m) => {
            const marketRequest = this.marketRequests.find(
                (r) => r.market === m
            );
            if (marketRequest) {
                this.removeMarketRequest(marketRequest);
            }
        });
    }

    validateMarkets(): boolean {
        if (this.marketRequests.length) {
            for (const market of this.marketRequests) {
                if (!market.usersTo.length) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    // TODO: [Vlad] - Change loading data
    @Watch("selectedItem")
    onMarketRequestChanged(val: Market | null, oldVal: Market | null): void {
        if (val && oldVal?.marketId !== val.marketId) {
            this.loadUsersByMarket(val.marketId);
        }
    }
}
