
import Vue from "vue";
import dateFormat from "dateformat";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxEditing,
    DxRangeRule,
    DxValidationRule,
    DxPaging,
    DxSelection
} from "devextreme-vue/data-grid";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import AppIcon from "@/components/AppIcon";
import { SupplyScenario } from "@/models/request/Request";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import {
    defaultConfig,
    RequestFormConfig
} from "@/models/request/RequestFormConfig";
import { VehicleModel } from "@/models/VehicleModel";
import { ScrollEvent } from "devextreme/ui/list";
import dxDataGrid, {
    ContextMenuPreparingEvent,
    EditingStartEvent,
    EditorPreparingEvent,
    Scrollable
} from "devextreme/ui/data_grid";
import { RequestFormValue } from "@/models/request/RequestFormValue";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { ModelGridConfig } from "@/models/ModelGridConfig";
import modelService from "@/services/modelService";
import { RequestModelFilter } from "@/models/request/RequestVehicleModelFilter";
import DataGridCopyService from "@/services/dataGridCopyService";
import { CurrentUserChange } from "@/components/RequestFormGrid/CurrentUserChange";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import { isValidDate } from "@/services/dateServices";
import { ValidTillDataRow } from "@/components/RequestFormGrid/ValidTillDataRow";

type ModelFilter =
    | "brands"
    | "series"
    | "modelRanges"
    | "fuelTypes"
    | "hybridFlags";

type FilterType = "include" | "exclude";

@Component({
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxHeaderFilter,
        DxEditing,
        DxRangeRule,
        DxValidationRule,
        DxPaging,
        DxSelection,
        DxLoadPanel,
        AppIcon
    },
    computed: {
        ...requestModule.mapGetters({
            editableConfig: RequestGetters.RequestFormConfig
        }),
        ...marketRequestModule.mapGetters({
            modelGridConfig: MarketRequestGetters.ModelGridConfig
        })
    },
    methods: {
        ...marketRequestModule.mapMutations({
            resetModelGridConfig: MarketRequestMutations.ResetModelGridConfig
        })
    }
})
export default class RequestFormGrid extends Vue {
    @Prop({ required: true, type: Array })
    public readonly models!: VehicleModel[];

    @Prop({ type: Array, default: () => [] })
    public readonly values!: RequestFormValue[];

    @Prop({ required: true, type: Array })
    public readonly scenarios!: SupplyScenario[];

    @Prop({ type: Boolean, default: false })
    public readonly configurable!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly readonly!: boolean;

    @Prop({ type: Boolean, default: true })
    public readonly showModels!: boolean;

    @Prop({ type: Array, default: () => [] })
    public readonly selectedModels!: VehicleModel[];

    @Prop({ type: Object, default: () => null })
    public readonly config!: RequestFormConfig | null;

    @Prop({ type: Boolean, default: false })
    public readonly isLoading!: boolean;

    private readonly editableConfig!: RequestFormConfig | null;
    protected readonly modelGridConfig!: ModelGridConfig;
    private readonly resetModelGridConfig!: () => void;

    protected width = 0;
    protected modelGridWidth = 0;

    public filter: RequestModelFilter = {
        brands: null,
        series: null,
        modelRanges: null,
        hybridFlags: null,
        fuelTypes: null
    };

    protected currentUserChange: CurrentUserChange = {
        cell: { row: undefined, column: undefined },
        filters: {
            brands: null,
            series: null,
            modelRanges: null,
            hybridFlags: null,
            fuelTypes: null
        }
    };
    protected readonly filterTypes: {
        [K in ModelFilter]: FilterType;
    } = {
        brands: "include",
        series: "include",
        modelRanges: "include",
        fuelTypes: "include",
        hybridFlags: "include"
    };
    protected readonly currencyFormat = "#,##0.##";
    protected readonly numberEditorOptions = {
        step: 0
    };
    protected readonly currencyEditorOptions = {
        step: 0,
        format: this.currencyFormat,
        showClearButton: false
    };

    private modelsIds: number[] = [];

    private copyService: DataGridCopyService | null = null;

    $refs!: Vue["$refs"] & {
        modelGrid: DxDataGrid;
        valuesGrid: DxDataGrid;
    };

    mounted(): void {
        this.width = this.$el.clientWidth;
        this.modelGridWidth = this.getModelGridWidth() ?? 0;

        this.updateSelectedModels(this.selectedModels);

        if (this.showModels) {
            const setModelGridScroll = () => {
                if (this.modelScrollable) {
                    this.modelScrollable.on("scroll", (e: ScrollEvent) => {
                        if (this.valuesScrollable) {
                            this.valuesScrollable.scrollTo({
                                top: e.scrollOffset.top,
                                left: this.valuesScrollable.scrollLeft()
                            });
                        }
                    });
                }
                this.$refs.modelGrid?.instance?.off("contentReady");
            };
            const setValueGridScroll = () => {
                if (this.valuesScrollable) {
                    this.valuesScrollable.on("scroll", (e: ScrollEvent) => {
                        if (this.modelScrollable) {
                            this.modelScrollable.scrollTo({
                                top: e.scrollOffset.top,
                                left: this.modelScrollable.scrollLeft()
                            });
                        }
                    });
                }
                this.dataGridInstance?.off("contentReady");
            };
            this.$refs.modelGrid?.instance?.on(
                "contentReady",
                setModelGridScroll
            );
            this.dataGridInstance?.on("contentReady", setValueGridScroll);
        }

        if (this.dataGridInstance) {
            this.copyService = new DataGridCopyService(this.dataGridInstance);
        }

        this.resetModelGridConfig();
    }

    get modelScrollable(): Scrollable | undefined {
        return this.$refs.modelGrid.instance?.getScrollable();
    }
    get valuesScrollable(): Scrollable | undefined {
        return this.$refs.valuesGrid.instance?.getScrollable();
    }

    get valueGridWidth(): number | null {
        if (!this.showModels) return this.width;

        if (this.width) {
            return this.width - this.modelGridWidth;
        }
        return null;
    }

    get dataGridInstance(): dxDataGrid<unknown, unknown> | undefined {
        return this.$refs.valuesGrid?.instance;
    }

    @Watch("modelGridConfig", { deep: true })
    updateModelGridWidth(): void {
        // We need to update the model grid width in next tick, because in the current one the component isn't rerendered so it'd be obtained outdated value
        this.$nextTick(() => {
            this.modelGridWidth = this.getModelGridWidth() ?? 0;
        });
    }

    getModelGridWidth(): number | null {
        if ((this.$refs.modelGrid as Vue)?.$el?.clientWidth) {
            return (this.$refs.modelGrid as Vue)?.$el?.clientWidth;
        }
        return null;
    }

    validateDate(params: { value: Date | null }): boolean {
        if (!params.value) return true;
        const year = params.value.getFullYear();
        // 1900 is the minimal date of Excel
        return year > 1899;
    }
    validateDateString(params: { value: string | null }): boolean {
        if (!params.value) return true;
        const parsedDate = new Date(params.value);
        const year = parsedDate.getFullYear();
        // 1900 is the minimal date of Excel
        return isValidDate(params.value) && year > 1899;
    }

    get gridHeight(): number | null {
        if (this.models.length || this.values.length) {
            return 700;
        }
        return null;
    }

    get valueGridConfig(): RequestFormConfig {
        if (this.configurable && this.editableConfig) {
            return this.editableConfig;
        } else if (this.config) {
            return this.config;
        } else {
            return defaultConfig;
        }
    }

    get computedValues(): RequestFormValue[] {
        if (this.configurable && this.models.length) {
            return this.models.map((m) => ({
                vehicleModelId: m.vehicleModelId
            }));
        }
        return this.values;
    }

    get hasSoleScenario(): boolean {
        return (
            this.scenarios.find((s) => s === SupplyScenario.Sole) !== undefined
        );
    }

    get hasDualScenario(): boolean {
        return (
            this.scenarios.find((s) => s === SupplyScenario.Dual) !== undefined
        );
    }

    get hasMultiScenario(): boolean {
        return (
            this.scenarios.find((s) => s === SupplyScenario.Multi) !== undefined
        );
    }

    get filteredModels(): VehicleModel[] {
        let models = this.models;
        if (this.filter.brands?.length) {
            models = this.filterModels(
                models,
                (m) => this.filter.brands?.includes(m.brand),
                this.filterTypes.brands
            );
        }
        if (this.filter.series?.length) {
            models = this.filterModels(
                models,
                (m) => this.filter.series?.includes(m.series),
                this.filterTypes.series
            );
        }
        if (this.filter.modelRanges?.length) {
            models = this.filterModels(
                models,
                (m) => this.filter.modelRanges?.includes(m.modelRange),
                this.filterTypes.modelRanges
            );
        }
        if (this.filter.fuelTypes?.length) {
            models = this.filterModels(
                models,
                (m) => this.filter.fuelTypes?.includes(m.fuelType),
                this.filterTypes.fuelTypes
            );
        }
        if (this.filter.hybridFlags?.length) {
            models = this.filterModels(
                models,
                (m) => this.filter.hybridFlags?.includes(m.hybridFlag),
                this.filterTypes.hybridFlags
            );
        }
        return models;
    }

    filterModels(
        models: VehicleModel[],
        expr: (v: VehicleModel) => boolean | null | undefined,
        type: FilterType
    ): VehicleModel[] {
        return type === "include"
            ? models.filter((m) => expr(m))
            : models.filter((m) => !expr(m));
    }

    get filteredValues(): RequestFormValue[] {
        const values: RequestFormValue[] = [];
        const filteredModels = this.filteredModels;

        filteredModels.forEach((m) => {
            const value = this.computedValues.find(
                (v) => v.vehicleModelId === m.vehicleModelId
            );
            if (value) values.push(value);
        });

        return values;
    }

    get selectedModelIds(): number[] {
        return this.modelsIds;
    }
    set selectedModelIds(values: number[]) {
        if (!this.readonly) {
            const newIds = values.filter((v) => !this.modelsIds.includes(v));
            const newModels = this.models.filter((m) =>
                newIds.includes(m.vehicleModelId)
            );
            this.$emit("add-models", newModels);

            const removedIds = this.modelsIds.filter(
                (v) => !values.includes(v)
            );
            const removedModels = this.models.filter((m) =>
                removedIds.includes(m.vehicleModelId)
            );
            this.$emit("remove-models", removedModels);

            this.modelsIds = values;
        }
    }

    validTill_calculateCellValue(
        rowData: ValidTillDataRow
    ): string | Date | null {
        return isValidDate(rowData.validTill) && rowData.validTill
            ? dateFormat(rowData.validTill, "yyyy/mm/dd")
            : rowData.validTill;
    }
    onEditingStart(e: EditingStartEvent): void {
        e.cancel = !this.selectedModelIds.includes(e.key);
    }

    editorPreparing(editor: EditorPreparingEvent): void {
        const currRowIdx = editor.row?.rowIndex;
        const dataField = editor.dataField;
        const onValueChanged = editor.editorOptions.onValueChanged;

        // Define editor behavior for a specific cell
        if (editor.dataField === "validTill") {
            if (editor.value && !isValidDate(editor.value))
                editor.editorOptions.value = null;
            editor.editorName = "dxDateBox";
            editor.editorOptions.displayFormat = "yyyy/MM/dd";
            editor.editorOptions.dateSerializationFormat = "yyyy/MM/dd";
        }

        // Takes the current cursor position to save the position after saving
        this.getCellPosition(currRowIdx, dataField);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editor.editorOptions.onKeyDown = function (e: any) {
            onValueChanged.apply(this, arguments);
            if (
                e.event.originalEvent.key === "Enter" &&
                currRowIdx !== undefined &&
                dataField
            ) {
                const rowCount = editor.component.getVisibleRows().length;

                if (currRowIdx + 1 < rowCount) {
                    e.event.originalEvent.stopPropagation();
                    setTimeout(() => {
                        editor.component.editCell(currRowIdx + 1, dataField);
                    }, 0);
                }
            }
        };
    }

    public setUserChange(userChange: CurrentUserChange): void {
        // We need wait next tick because data not always load on
        // time and therefore setting the filter doesn't work.
        this.$nextTick(() => {
            if (userChange.filters) this.filter = userChange.filters;
            // We need wait timer because after setting filter we need wait some time to load,
            // but nextTick() method in this case does not give the desired result.
            setTimeout(() => {
                if (
                    userChange.cell.row != undefined &&
                    userChange.cell.column != undefined
                )
                    this.dataGridInstance?.editCell(
                        userChange.cell.row,
                        userChange.cell.column
                    );
            }, 300);
        });
    }

    public getUserChange(): CurrentUserChange {
        this.currentUserChange.filters = { ...this.filter };
        return this.currentUserChange;
    }

    protected callSetUserChange(): void {
        this.$emit("set-user-change");
    }

    private getCellPosition(
        currRowIdx: number | undefined,
        dataField: string | undefined
    ) {
        if (currRowIdx && dataField) {
            this.currentUserChange.cell.row = currRowIdx;
            this.currentUserChange.cell.column =
                this.dataGridInstance?.getVisibleColumnIndex(dataField);
        }
    }

    @Watch("filter", { deep: true })
    updateFilter(): void {
        this.copyService?.cancel();
    }

    @Watch("selectedModels")
    updateSelectedModels(newVal: VehicleModel[]): void {
        this.modelsIds = newVal.map((m) => m.vehicleModelId);
        // if not all models are selected, then update the filter
        if (newVal.length < this.models.length) {
            // Get filter information from UCP vehicle models because model series and ranges may differ from those stored in the database
            // e.g. ucp responses with a series of "i" and the database stores a series of "I" so the filter will have the wrong meaning, it depends on market
            const selectedModels = this.models.filter((m) =>
                this.modelsIds.includes(m.vehicleModelId)
            );
            this.filter = modelService.getInfoFromModels(selectedModels);
        } else {
            this.filter = {
                brands: null,
                series: null,
                modelRanges: null,
                hybridFlags: null,
                fuelTypes: null
            };
        }
    }

    // Create a custom data source for the header filter, because if you set the filtered value on the first render, there will be no value in the filter header.
    get seriesDataSource(): unknown[] {
        const filter = modelService.getInfoFromModels(this.models);
        return (
            filter.series?.map((m) => ({
                text: m,
                value: m
            })) ?? []
        );
    }
    get modelRangesDataSource(): unknown[] {
        const filter = modelService.getInfoFromModels(this.models);
        return (
            filter.modelRanges?.map((m) => ({
                text: m,
                value: m
            })) ?? []
        );
    }
    get brandDataSource(): unknown[] {
        const filter = modelService.getInfoFromModels(this.models);
        return (
            filter.brands?.map((m) => ({
                text: m,
                value: m
            })) ?? []
        );
    }
    get fuelTypeDataSource(): unknown[] {
        const filter = modelService.getInfoFromModels(this.models);
        return (
            filter.fuelTypes?.map((m) => ({
                text: m,
                value: m
            })) ?? []
        );
    }
    get hybridFlagDataSource(): unknown[] {
        const filter = modelService.getInfoFromModels(this.models);
        return (
            filter.hybridFlags?.map((m) => ({
                text: m,
                value: m
            })) ?? []
        );
    }

    addMenuItems(e: ContextMenuPreparingEvent<RequestFormValue, number>): void {
        if (e.target == "content" && !this.readonly && this.copyService) {
            // e.items can be undefined
            if (!e.items) e.items = [];

            // Add a custom menu item
            if (!this.copyService.isCopied()) {
                e.items.push({
                    text: "Copy from",
                    onItemClick: () => {
                        if (e.row) {
                            this.copyService?.copy(
                                e.columnIndex,
                                e.rowIndex,
                                e.row
                            );
                        }
                    }
                });
            } else {
                e.items.push(
                    {
                        text: "Paste to",
                        onItemClick: () => {
                            if (e.row) {
                                this.copyService?.paste(
                                    e.columnIndex,
                                    e.rowIndex
                                );
                            }
                        }
                    },
                    {
                        text: "Cancel copying",
                        onItemClick: () => {
                            this.copyService?.cancel();
                        }
                    }
                );
            }
        }
    }
}
