export function getDefaultEditableFields(): string[] {
    return [
        "request type",
        "customer",
        "IKAMs",
        "supply scenarios",
        "market deadline",
        "customer deadline",
        "international bonus",
        "expected volume",
        "total fleet",
        "attachments",
        "additional information",
        "questions"
    ];
}
