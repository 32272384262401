
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { DxPopup } from "devextreme-vue/popup";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import { Request } from "@/models/request/Request";
import { RequestMutations } from "@/store/modules/request/mutations";
import dxScrollView from "devextreme/ui/scroll_view";
import { RequestAdditionalField } from "@/models/request/RequestAdditionalField";

@Component({
    components: { DxButton, DxPopup, DxTextBox, DxCheckBox, DxScrollView },
    computed: {
        ...requestModule.mapGetters({
            config: RequestGetters.RequestFormConfig,
            request: RequestGetters.MarketRequest,
            configTemplate: RequestGetters.FormConfigTemplate
        })
    },
    methods: {
        ...requestModule.mapMutations({
            updateConfig: RequestMutations.UpdateFormConfig
        })
    }
})
export default class FormConfigSettings extends Vue {
    private readonly updateConfig!: (
        config: Partial<RequestFormConfig>
    ) => void;

    protected readonly configTemplate!: RequestFormConfig | null;
    protected readonly config!: RequestFormConfig;
    protected readonly request!: Request;

    protected popupVisible = false;
    protected newField = "";

    $refs!: Vue["$refs"] & {
        scrollView: DxScrollView;
    };

    updateValue(field: string, value: boolean): void {
        this.updateConfig({ [field]: value });
    }

    addNewField(): void {
        if (this.newField) {
            if (this.config.additionalFields) {
                this.updateConfig({
                    additionalFields: [
                        ...this.config.additionalFields,
                        { text: this.newField }
                    ]
                });
            } else {
                this.updateConfig({
                    additionalFields: [{ text: this.newField }]
                });
            }
            this.newField = "";
            this.$nextTick(() => {
                if (this.scrollView) {
                    this.scrollView.scrollBy(100);
                }
            });
        }
    }

    removeField(field: RequestAdditionalField): void {
        this.updateConfig({
            additionalFields: this.config.additionalFields.filter(
                (f) => f !== field
            )
        });
    }

    updateField(field: RequestAdditionalField, value: string): void {
        if (value) {
            const fields = [...this.config.additionalFields];
            const index = fields.indexOf(field);
            if (index !== -1) {
                fields[index] = { ...fields[index], text: value };
                this.updateConfig({ additionalFields: fields });
            }
        }
    }

    get scrollView(): dxScrollView | undefined {
        return this.$refs.scrollView.instance;
    }
}
