
import Vue from "vue";
import Component from "vue-class-component";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { Request } from "@/models/request/Request";
import { RequestMutations } from "@/store/modules/request/mutations";
import { DxDateBox } from "devextreme-vue/date-box";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxValidator, {
    DxRequiredRule,
    DxStringLengthRule,
    DxRangeRule
} from "devextreme-vue/validator";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import DxList from "devextreme-vue/list";
import DxTextArea from "devextreme-vue/text-area";
import RequestMarketsForm from "../RequestMarketsForm";
import { customerModule } from "@/store/modules/customer";
import { userModule } from "@/store/modules/user";
import { CustomerGetters } from "@/store/modules/customer/getters";
import { Customer } from "@/models/Customer";
import { CustomerActions } from "@/store/modules/customer/actions";
import { UserActions } from "@/store/modules/user/actions";
import RequestQuestionList from "../RequestQuestionList";
import FileUploader from "@/components/FileUploader";
import { LookupEntity } from "@/models/LookupEntity";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestInfo } from "@/models/request/RequestInfo";
import { UserGetters } from "@/store/modules/user/getters";

@Component({
    components: {
        DxSelectBox,
        DxTextBox,
        DxDropDownBox,
        DxList,
        DxDateBox,
        DxNumberBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxRangeRule,
        DxTextArea,
        FileUploader,
        RequestQuestionList,
        RequestMarketsForm
    },
    computed: {
        ...requestModule.mapGetters({
            request: RequestGetters.MarketRequest,
            info: RequestGetters.Info
        }),
        ...customerModule.mapGetters({
            customers: CustomerGetters.Customers
        }),
        ...userModule.mapGetters({
            IKAMs: UserGetters.LookupIkams
        })
    },
    methods: {
        ...customerModule.mapActions({
            loadCustomers: CustomerActions.LoadCustomers
        }),
        ...userModule.mapActions({
            loadIKAMs: UserActions.LoadIKAMs
        }),
        ...requestModule.mapMutations({
            updateRequest: RequestMutations.UpdateRequest,
            addAttachment: RequestMutations.AddAttachment,
            removeAttachment: RequestMutations.RemoveAttachment,
            setEditingState: RequestMutations.SetEditingState
        }),
        ...requestModule.mapActions({
            loadInfo: RequestActions.LoadInfo
        })
    }
})
export default class RequestSettingsForm extends Vue {
    private readonly loadIKAMs!: () => Promise<void>;
    private readonly loadInfo!: () => Promise<void>;
    private readonly loadCustomers!: () => Promise<void>;
    private readonly updateRequest!: (payload: Partial<Request>) => void;
    private readonly setEditingState!: (payload: boolean) => void;

    protected readonly addAttachment!: (file: File) => void;
    protected readonly removeAttachment!: (file: string | number) => void;

    protected readonly info!: RequestInfo;
    protected readonly IKAMs!: LookupEntity<string>[];
    protected readonly request!: Request;
    protected readonly customers!: Customer[];

    protected newQuestion: string | null = null;

    protected customerFilter = true;

    mounted(): void {
        this.loadInfo().then(() => {
            if (!this.request.supplyScenarios?.length) {
                const multiId = this.info.supplyScenarios.find(
                    (s) => s.name === "Multi"
                )?.supplyScenarioId;
                if (multiId) {
                    this.updateRequest({ supplyScenarios: [multiId] });
                    this.setEditingState(false);
                }
            }
            if (this.request.customer && !this.request.customer.isCustomer) {
                this.customerFilter = false;
            }
        });
        this.loadCustomers();
        this.loadIKAMs();
    }

    get currentType(): number | null {
        return this.request.requestTypeId;
    }

    set currentType(value: number | null) {
        if (value) {
            this.updateRequest({ requestTypeId: value });
        }
    }

    get IKAM(): number | null {
        return this.request.ikamId;
    }

    set IKAM(value: number | null) {
        if (value) this.updateRequest({ ikamId: value });
    }

    get customerDeadline(): Date {
        return this.request.customerDeadlineDate;
    }

    set customerDeadline(value: Date) {
        this.updateRequest({ customerDeadlineDate: value });
    }

    get marketDeadline(): Date {
        return this.request.marketDeadlineDate;
    }

    set marketDeadline(value: Date) {
        this.updateRequest({ marketDeadlineDate: value });
    }

    get filteredCustomers(): Customer[] {
        return this.customers.filter(
            (c) => c.isCustomer === this.customerFilter
        );
    }

    get currentCustomer(): number | null {
        return this.request.customerId ?? null;
    }

    set currentCustomer(value: number | null) {
        const customer = this.customers.find((c) => c.customerId === value);
        if (customer) {
            this.updateRequest({
                customerId: value,
                ikamId: customer.ikamId
            });
        }
    }

    get additionalInformation(): string {
        return this.request.additionalInformation ?? "";
    }

    set additionalInformation(value: string) {
        this.updateRequest({ additionalInformation: value });
    }

    get expectedVolume(): string {
        return this.request.expectedVolume ?? "";
    }
    set expectedVolume(value: string) {
        this.updateRequest({ expectedVolume: value });
    }

    get totalFleet(): string {
        return this.request.totalFleet ?? "";
    }
    set totalFleet(value: string) {
        this.updateRequest({ totalFleet: value });
    }

    get supplyScenarios(): number[] {
        return this.request.supplyScenarios;
    }

    set supplyScenarios(value: number[]) {
        // When the component is loaded, this setter is called with the same value and causes the edit state to change. To avoid this behavior, check the array for equality
        if (value.length === this.supplyScenarios.length) {
            let same = true;
            for (let i = 0; i < value.length; i++) {
                if (value[i] !== this.supplyScenarios[i]) {
                    same = false;
                    break;
                }
            }
            if (same) return;
        }
        this.updateRequest({ supplyScenarios: value });
    }

    get internationalBonus(): string | null {
        return this.request.internationalBonus;
    }

    set internationalBonus(value: string | null) {
        if (value) {
            this.updateRequest({ internationalBonus: value });
        }
    }
}
