
import Vue from "vue";
import { Component, Emit, Prop, PropSync } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxButton } from "devextreme-vue/button";
import { DxTextArea } from "devextreme-vue/text-area";

@Component({
    components: { DxPopup, DxButton, DxTextArea }
})
export default class RequestConfirmationPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    protected popupVisible!: boolean;

    @Prop({ type: Boolean, default: false })
    public showAttention!: boolean;

    @Prop({ type: String, default: "Finalize request" })
    public title!: string;

    @Prop({ type: String, default: "Finalize" })
    public confirmButton!: string;

    protected message = "";

    @Emit()
    confirm(): string {
        this.popupVisible = false;
        const tempMessage = this.message;
        this.message = "";
        return tempMessage;
    }

    @Emit()
    cancel(): void {
        this.popupVisible = false;
    }
}
