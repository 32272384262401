var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"content-template":"popup-content","title":"Compare mode","visible":_vm.popupVisible,"close-on-outside-click":false,"show-title":true,"height":700,"width":800,"resize-enabled":false,"drag-enabled":false},on:{"update:visible":function($event){_vm.popupVisible=$event}},scopedSlots:_vm._u([{key:"popup-content",fn:function(){return [_c('div',{staticClass:"popup-container"},[_c('div',{staticClass:"popup-content"},[(_vm.requestDataSource)?_c('RequestList',{attrs:{"data-source":_vm.requestDataSource,"height":550,"columns":[
                        'requestType', 
                        'customer', 
                        'finishedDate', 
                        'finishedBy', 
                        'state'
                    ],"loading":_vm.loading},on:{"click-on-request":_vm.selectRequest}}):_vm._e()],1),_c('div',{staticClass:"popup-buttons d-flex justify-content-end mt-2"},[_c('BaseButton',{attrs:{"width":200,"simple":""},on:{"click":function($event){_vm.popupVisible=false}}},[_vm._v(" Cancel ")])],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }