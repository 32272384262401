import dxDataGrid, { Row } from "devextreme/ui/data_grid";
import dateFormat from "dateformat";
import { isValidDate } from "@/services/dateServices";

type Coordinate = {
    x: number | null;
    y: number | null;
};

export default class DataGridCopyService {
    private copyCords: Coordinate = { x: null, y: null };
    private pasteCords: Coordinate = { x: null, y: null };
    private copiedValue: string | number | null = null;

    private dataGrid: dxDataGrid;

    constructor(dataGrid: dxDataGrid) {
        this.dataGrid = dataGrid;
    }

    public isCopied(): boolean {
        return (
            this.copyCords.x != null &&
            this.copyCords.y !== null &&
            this.copiedValue !== null
        );
    }

    public copy(columnIndex: number, rowIndex: number, row: Row): void {
        this.setCoordData(this.copyCords, columnIndex, rowIndex);
        if (row.values[columnIndex] instanceof Date) {
            this.copiedValue = dateFormat(
                row.values[columnIndex],
                "yyyy/mm/dd"
            );
        } else {
            this.copiedValue = row.values[columnIndex];
        }
    }

    public paste(columnIndex: number, rowIndex: number): void {
        this.setCoordData(this.pasteCords, columnIndex, rowIndex);

        this.fillValues();

        this.dataGrid.saveEditData();

        this.cancel();
    }

    public cancel(): void {
        this.copyCords = { x: null, y: null };
        this.pasteCords = { x: null, y: null };
        this.copiedValue = null;
    }

    private setCoordData(coord: Coordinate, x: number, y: number) {
        coord.x = x;
        coord.y = y;
    }

    private fillValues(): void {
        if (
            this.pasteCords.x !== null &&
            this.copyCords.x !== null &&
            this.pasteCords.y !== null &&
            this.copyCords.y !== null &&
            this.copiedValue !== null
        ) {
            if (this.pasteCords.x > this.copyCords.x) {
                for (let x = this.copyCords.x; x <= this.pasteCords.x; x++) {
                    this.fillColumn(x, this.copyCords.y, this.pasteCords.y);
                }
            } else {
                for (let x = this.copyCords.x; x >= this.pasteCords.x; x--) {
                    this.fillColumn(x, this.copyCords.y, this.pasteCords.y);
                }
            }
        }
    }

    private fillColumn(x: number, start: number, end: number): void {
        const currentColumn = this.dataGrid.getVisibleColumns()[x];

        // if current column is number and copied value isn't number -> skip current column
        if (
            isNaN(Number(this.copiedValue)) &&
            currentColumn.dataType === "number"
        ) {
            return;
        }

        // It should be checked what type of value we write to a cell to avoid conversion errors on the backend
        let value = this.copiedValue;
        if (
            currentColumn.dataType === "date" &&
            typeof this.copiedValue === "number"
        ) {
            return;
        } else if (
            currentColumn.dataType === "date" &&
            typeof this.copiedValue === "string" &&
            !isValidDate(this.copiedValue)
        ) {
            return;
        } else if (
            currentColumn.dataType === "date" &&
            this.copiedValue !== null
        ) {
            value = dateFormat(this.copiedValue, "yyyy-mm-dd");
        } else if (
            currentColumn.dataType !== "number" &&
            this.copiedValue !== null
        ) {
            value = this.copiedValue?.toString();
        }

        if (end > start) {
            for (let y = start; y <= end; y++) {
                this.dataGrid.cellValue(y, x, value);
            }
        } else {
            for (let y = start; y >= end; y--) {
                this.dataGrid.cellValue(y, x, value);
            }
        }
    }
}
