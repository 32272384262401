
import Vue from "vue";
import Component from "vue-class-component";
import { DxTextBox } from "devextreme-vue/text-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestMutations } from "@/store/modules/request/mutations";
import AppIcon from "../AppIcon";
import { Question } from "@/models/Question";
import { Prop } from "vue-property-decorator";

@Component({
    components: { DxTextBox, DxValidator, DxRequiredRule, AppIcon },
    computed: {
        ...requestModule.mapGetters({
            questions: RequestGetters.RequestQuestions
        })
    },
    methods: {
        ...requestModule.mapMutations({
            updateQuestions: RequestMutations.UpdateQuestions
        })
    }
})
export default class RequestQuestionList extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly isQuestionReadonly!: boolean;
    @Prop({ type: Boolean, default: true })
    public readonly canManageQuestions!: boolean;

    private readonly questions!: Question[];

    private readonly updateQuestions!: (payload: Question[]) => void;

    protected newQuestion: string | null = null;

    getQuestionNumber(index: number): string {
        index += 1;
        if (index < 10) {
            return "0" + index;
        }
        return index.toString();
    }

    addNewQuestion(): void {
        if (this.newQuestion) {
            this.updateQuestions([
                ...this.questions,
                { text: this.newQuestion }
            ]);
            this.newQuestion = null;
        }
    }

    deleteQuestion(index: number): void {
        const questions = [...this.questions];
        questions.splice(index, 1);
        this.updateQuestions(questions);
    }

    questionChanged(index: number, value: string): void {
        const questions = [...this.questions];
        questions[index] = { text: value };
        this.updateQuestions(questions);
    }
}
