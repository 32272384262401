import { RequestAdditionalField } from "./RequestAdditionalField";

export interface RequestFormConfig {
    id?: number;
    vehicleDiscountPercent: boolean;
    vehicleDiscountValue: boolean;
    optionDiscount: boolean;
    validFrom: boolean;
    validTill: boolean;
    comments: boolean;
    price: boolean;
    vehiclePriceList: boolean;
    sumPrice: boolean;
    additionalFields: RequestAdditionalField[];
}

export const defaultConfig: RequestFormConfig = {
    vehicleDiscountPercent: true,
    vehicleDiscountValue: true,
    optionDiscount: true,
    validFrom: true,
    validTill: true,
    comments: true,
    price: true,
    vehiclePriceList: true,
    sumPrice: true,
    additionalFields: []
};
