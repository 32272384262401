var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('DxButton',{attrs:{"element-attr":{ id: 'copyButton' },"icon":"copy","type":"default"},on:{"click":function($event){_vm.popupVisible=true}}}),_c('DxTooltip',{attrs:{"hide-on-outside-click":false,"target":"#copyButton","show-event":"mouseenter","hide-event":"mouseleave"}},[_vm._v(" Copy ")])],1),_c('DxPopup',{attrs:{"content-template":"popup-content","visible":_vm.popupVisible,"close-on-outside-click":false,"show-title":true,"height":750,"width":750,"resize-enabled":false,"drag-enabled":false},on:{"update:visible":function($event){_vm.popupVisible=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('h3',[_vm._v("Copy Terms")]),_c('div',{staticClass:"warning-message"},[_vm._v(" Warning! Please check Validity of the copied terms afterwards. ")])])]},proxy:true},{key:"popup-content",fn:function(){return [_c('div',[_c('div',{staticClass:"popup-container"},[_c('div',{staticClass:"popup-content"},[(_vm.requestDataSource)?_c('RequestList',{attrs:{"data-source":_vm.requestDataSource,"height":600,"columns":[
                                'requestType', 
                                'customer', 
                                'finishedDate', 
                                'finishedBy', 
                                'state'
                            ],"buttons":['copy'],"loading":_vm.loading},on:{"copy-button-click":_vm.InitSupplyPopup}}):_vm._e()],1),_c('div',{staticClass:"popup-buttons d-flex justify-content-end mt-2"},[_c('BaseButton',{attrs:{"width":200,"simple":""},on:{"click":function($event){_vm.popupVisible=false}}},[_vm._v(" Cancel ")])],1)]),(_vm.popupVisible && _vm.selectedRequestId)?_c('CopyTermsSupplyScenarioPopup',{attrs:{"visible":_vm.supplyPopupVisible,"selected-request-id":_vm.selectedRequestId},on:{"update:visible":function($event){_vm.supplyPopupVisible=$event},"submit":_vm.copyTerms}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }