
import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import BaseButton from "@/components/BaseButton";
import { DxButton } from "devextreme-vue/button";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestActions } from "@/store/modules/request/actions";
import RequestList from "@/components/RequestList";
import { MarketRequestState } from "@/models/request/MarketRequest";
import CopyTermsSupplyScenarioPopup from "./CopyTermsSupplyScenarioPopup";
import { SupplyScenarioMapping } from "./SupplyScenarioMapping";
import { DxTooltip } from "devextreme-vue";
import CustomStore from "devextreme/data/custom_store";

@Component({
    components: {
        DxPopup,
        DxTooltip,
        BaseButton,
        DxButton,
        RequestList,
        CopyTermsSupplyScenarioPopup
    },
    computed: {
        ...requestModule.mapGetters({
            loading: RequestGetters.IsLoading
        })
    },
    methods: {
        ...requestModule.mapActions({
            createDataSource: RequestActions.CreateRequestDataSource
        })
    }
})
export default class CopyTerms extends Vue {
    private readonly createDataSource!: (payload: {
        marketId: number;
        marketRequestState?: MarketRequestState;
    }) => Promise<CustomStore>;

    protected readonly loading!: boolean;
    protected supplyPopupVisible = false;
    protected selectedRequestId: number | null = null;
    @Prop({ type: Number, required: true })
    public readonly marketId!: number;

    protected popupVisible = false;

    protected requestDataSource: CustomStore | null = null;

    @Watch("popupVisible")
    async popupVisibleChanged(newVal: boolean): Promise<void> {
        if (newVal) {
            this.requestDataSource = await this.createDataSource({
                marketId: this.marketId,
                marketRequestState: MarketRequestState.Approved
            });
        }
    }

    InitSupplyPopup(requestId: number): void {
        this.supplyPopupVisible = true;
        this.selectedRequestId = requestId;
    }

    @Emit("copy-request")
    copyTerms(
        supplyScenarioMapping: SupplyScenarioMapping
    ): SupplyScenarioMapping {
        this.popupVisible = false;
        return supplyScenarioMapping;
    }
}
