
import { Component, Prop, PropSync } from "vue-property-decorator";
import Vue from "vue";
import { DxButton, DxPopup, DxScrollView } from "devextreme-vue";
import { ImportResult } from "@/models/request/ImportResult";

type ImportErrorGroup = {
    group: string;
    errors: string[];
};

@Component({
    components: {
        DxPopup,
        DxScrollView,
        DxButton
    }
})
export default class ImportResultPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: Object, required: true })
    public readonly importResult!: ImportResult;

    protected width = 900;
    protected height = 600;

    get isSuccessful(): boolean {
        return this.importResult.succeeded;
    }

    get rowImported(): number {
        return this.importResult.successfullyImportedRows;
    }

    get mappedErrors(): ImportErrorGroup[] {
        if (this.isSuccessful) {
            return [];
        }

        return Object.keys(this.importResult.errors).map((key) => ({
            group: key,
            errors: this.importResult.errors[key]
        }));
    }
}
