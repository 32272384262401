
import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonVehicleEntry } from "@/models/request/compare/RequestComparisonVehicleEntry";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DxSelectBox from "devextreme-vue/select-box";
import DxNumberBox from "devextreme-vue/number-box";
import { ComparisonVehicleDTO } from "@/models/request/compare/ComparisonVehicleDTO";
import { ComparisonVehicleValueDetailsDTO } from "@/models/request/compare/ComparisonVehicleValueDetailsDTO";

@Component({
    components: { DxSelectBox, DxNumberBox }
})
export default class ComparisonVehicleItem extends Vue {
    @Prop({ type: Object, required: true })
    public readonly vehicleComparisonEntry!: RequestComparisonVehicleEntry;

    @Prop({ type: Number, required: true })
    public readonly originalSupplyScenarioId!: number;

    @Prop({ type: Number, required: true })
    public readonly comparedSupplyScenarioId!: number;

    @Prop({ type: Object, required: true })
    public readonly details!: ComparisonDetailsDTO;

    get showOriginalDiscountPercentColumn(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountPercent;
    }
    get showEmptyOriginalDiscountPercentColumn(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountPercent;
    }

    get showOriginalDiscountValueColumn(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountValue;
    }
    get showEmptyOriginalDiscountValueColumn(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountValue;
    }

    get showOriginalOptionDiscountValueColumn(): boolean {
        return this.details.optionsDto.originalHasOptionDiscountValue;
    }
    get showEmptyOriginalOptionDiscountValueColumn(): boolean {
        return this.details.optionsDto.comparedHasOptionDiscountValue;
    }

    get showComparedDiscountPercentColumn(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountPercent;
    }
    get showEmptyComparedDiscountPercentColumn(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountPercent;
    }

    get showComparedDiscountValueColumn(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountValue;
    }
    get showEmptyComparedDiscountValueColumn(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountValue;
    }

    get showComparedOptionDiscountValueColumn(): boolean {
        return this.details.optionsDto.comparedHasOptionDiscountValue;
    }
    get showEmptyComparedOptionDiscountValueColumn(): boolean {
        return this.details.optionsDto.originalHasOptionDiscountValue;
    }

    get originalVehicleModel(): ComparisonVehicleDTO | undefined {
        return (
            this.details.originalRequestModels.find(
                (m) =>
                    m.vehicleModelId ===
                    this.vehicleComparisonEntry.originalVehicleId
            ) ?? undefined
        );
    }

    get comparedVehicleModel(): ComparisonVehicleDTO | undefined {
        return (
            this.details.comparedRequestModels.find(
                (m) =>
                    m.vehicleModelId ===
                    this.vehicleComparisonEntry.comparedVehicleId
            ) ?? undefined
        );
    }

    getComparisonVehicleName(
        comparisonVehicle: ComparisonVehicleDTO | null
    ): string | null {
        if (comparisonVehicle) {
            return comparisonVehicle.modelName
                ? `${comparisonVehicle.vehicleCode} | ${comparisonVehicle.modelName}`
                : comparisonVehicle.vehicleCode;
        }
        return null;
    }
    getCellStyle(
        originalValue: number | null,
        comparedValue: number | null
    ): string {
        if (originalValue == null && comparedValue == null) return "";

        if (originalValue == null && comparedValue != null && comparedValue > 0)
            return "orange-background";

        if (comparedValue == null && originalValue != null && originalValue > 0)
            return "green-background";

        if (originalValue != null && comparedValue != null) {
            if (originalValue < comparedValue) return "orange-background";
            if (originalValue > comparedValue) return "green-background";
        }

        return "yellow-background";
    }

    get originalVehicleValue(): ComparisonVehicleValueDetailsDTO | undefined {
        if (this.originalVehicleModel)
            return this.details.originalVehicleValues.find(
                (v) =>
                    v.vehicleModelId ==
                    this.originalVehicleModel?.vehicleModelId
            );
        return undefined;
    }

    get comparedVehicleValue(): ComparisonVehicleValueDetailsDTO | undefined {
        if (this.comparedVehicleModel)
            return this.details.comparedVehicleValues.find(
                (v) =>
                    v.vehicleModelId ==
                    this.comparedVehicleModel?.vehicleModelId
            );
        return undefined;
    }

    get vehicleDiscountPercentCellStyle(): string {
        return this.getCellStyle(
            this.originalVehicleDiscountPercent,
            this.comparedVehicleDiscountPercent
        );
    }
    get vehicleDiscountValueCellStyle(): string {
        return this.getCellStyle(
            this.originalVehicleDiscountValue,
            this.comparedVehicleDiscountValue
        );
    }
    get vehicleOptionDiscountValueCellStyle(): string {
        return this.getCellStyle(
            this.originalOptionDiscountValue,
            this.comparedOptionDiscountValue
        );
    }

    get originalVehicleDiscountPercent(): number | null {
        if (!this.details.optionsDto.originalHasVehicleDiscountPercent)
            return null;
        if (this.originalVehicleValue)
            return (
                this.originalVehicleValue.supplyScenarioVehicleDiscountPercent[
                    this.originalSupplyScenarioId
                ] ?? null
            );
        return null;
    }
    get originalVehicleDiscountValue(): number | null {
        if (!this.details.optionsDto.originalHasVehicleDiscountValue)
            return null;
        if (this.originalVehicleValue)
            return (
                this.originalVehicleValue.supplyScenarioVehicleDiscountValue[
                    this.originalSupplyScenarioId
                ] ?? null
            );
        return null;
    }
    get originalOptionDiscountValue(): number | null {
        if (!this.details.optionsDto.originalHasOptionDiscountValue)
            return null;
        if (this.originalVehicleValue)
            return (
                this.originalVehicleValue.supplyScenarioOptionDiscount[
                    this.originalSupplyScenarioId
                ] ?? null
            );
        return null;
    }

    get comparedVehicleDiscountPercent(): number | null {
        if (!this.details.optionsDto.comparedHasVehicleDiscountPercent)
            return null;
        if (this.comparedVehicleValue)
            return (
                this.comparedVehicleValue.supplyScenarioVehicleDiscountPercent[
                    this.comparedSupplyScenarioId
                ] ?? null
            );
        return null;
    }
    get comparedVehicleDiscountValue(): number | null {
        if (!this.details.optionsDto.comparedHasVehicleDiscountValue)
            return null;
        if (this.comparedVehicleValue)
            return (
                this.comparedVehicleValue.supplyScenarioVehicleDiscountValue[
                    this.comparedSupplyScenarioId
                ] ?? null
            );
        return null;
    }
    get comparedOptionDiscountValue(): number | null {
        if (!this.details.optionsDto.comparedHasOptionDiscountValue)
            return null;
        if (this.comparedVehicleValue)
            return (
                this.comparedVehicleValue.supplyScenarioOptionDiscount[
                    this.comparedSupplyScenarioId
                ] ?? null
            );

        return null;
    }
    get isDifferentModels(): boolean {
        if (
            this.vehicleComparisonEntry.originalVehicleId &&
            this.vehicleComparisonEntry.comparedVehicleId
        ) {
            return (
                this.vehicleComparisonEntry.originalVehicleId !==
                this.vehicleComparisonEntry.comparedVehicleId
            );
        }
        return true;
    }
}
