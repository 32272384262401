import { Request } from "@/models/request/Request";

// General request validation function
function validateRequest(request: Request, errors?: string[]): boolean {
    let result = true;

    if (!request.requestTypeId) {
        errors?.push("Request type is required");
        result = false;
    }
    if (!request.customerId) {
        errors?.push("Customer is required");
        result = false;
    }
    if (!request.ikamId) {
        errors?.push("IKAM is required");
        result = false;
    }
    if (!request.supplyScenarios.length) {
        errors?.push("Supply scenario is required");
        result = false;
    }
    if (!request.customerDeadlineDate) {
        errors?.push("Customer deadline is required");
        result = false;
    }
    if (!request.marketDeadlineDate) {
        errors?.push("Market deadline is required");
        result = false;
    }
    if (!request.internationalBonus) {
        errors?.push("Internal bonus is required");
        result = false;
    }
    if (!request.marketRequests.length) {
        errors?.push("At least one market needs to be selected");
        result = false;
    } else {
        if (request.marketRequests.some((m) => !m.usersTo.length)) {
            errors?.push(
                "Each market needs to have at least one contact person."
            );
            result = false;
        }
    }

    return result;
}

export default {
    validateRequest
};
