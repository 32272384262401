
import Vue from "vue";
import Component from "vue-class-component";
import { RequestDetails } from "@/models/request/RequestDetails";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { DxTextArea } from "devextreme-vue/text-area";
import { Attachment } from "@/models/Attachment";
import AppIcon from "@/components/AppIcon";
import { isUserInRoles } from "@/services/userUtils";

@Component({
    components: { DxTextArea, AppIcon },
    computed: {
        ...marketRequestModule.mapGetters({
            request: MarketRequestGetters.Request
        })
    }
})
export default class RequestSummary extends Vue {
    private readonly request!: RequestDetails | null;

    get isMarketUser(): boolean {
        return isUserInRoles(["MSU", "MU"]);
    }

    get type(): string {
        return this.request?.type ?? "";
    }
    get supplyScenarios(): string {
        return this.request?.supplyScenarios.join(", ") ?? "";
    }
    get customer(): string {
        return this.request?.customer.name ?? "";
    }
    get IKAM(): string {
        if (this.request?.ikam) {
            return `${this.request.ikam.firstName} ${this.request.ikam.lastName}`;
        }
        return "";
    }
    get marketDeadline(): string {
        if (!this.request) return "";
        return new Date(
            this.request.marketDeadlineDate as never
        ).toLocaleDateString();
    }
    get customerDeadline(): string {
        if (!this.request) return "";
        return new Date(
            this.request.customerDeadlineDate as never
        ).toLocaleDateString();
    }
    get validity(): string {
        return this.request?.validity ?? "";
    }
    get additionalInformation(): string {
        return this.request?.additionalInformation ?? "";
    }
    get expectedVolume(): string {
        return this.request?.expectedVolume ?? "";
    }
    get internationalBonus(): string {
        return this.request?.internationalBonus ?? "";
    }
    get finalComment(): string | undefined {
        return this.request?.finalComment;
    }
    get attachments(): Attachment[] {
        return this.request?.attachments ?? [];
    }
    get totalFleet(): string | undefined {
        return this.request?.totalFleet;
    }
}
