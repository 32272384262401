
import Vue from "vue";
import { Component, Emit, Prop, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import BaseButton from "@/components/BaseButton";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import RequestList from "@/components/RequestList";
import { MarketRequestState } from "@/models/request/MarketRequest";
import CustomStore from "devextreme/data/custom_store";

@Component({
    components: { DxPopup, BaseButton, RequestList },
    computed: {
        ...requestModule.mapGetters({
            loading: RequestGetters.IsLoading
        })
    },
    methods: {
        ...requestModule.mapActions({
            createDataSource: RequestActions.CreateRequestDataSource
        })
    }
})
export default class ComparingRequestListPopup extends Vue {
    private readonly createDataSource!: (payload: {
        marketId: number;
        marketRequestState?: MarketRequestState;
    }) => Promise<CustomStore>;

    protected readonly requests!: RequestListItem[];
    protected readonly loading!: boolean;

    protected requestDataSource: CustomStore | null = null;

    @Prop({ type: Number, required: true })
    public readonly marketId!: number;
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Watch("popupVisible")
    async popupVisibleChanged(newVal: boolean): Promise<void> {
        if (newVal) {
            this.requestDataSource = await this.createDataSource({
                marketId: this.marketId,
                marketRequestState: MarketRequestState.Approved
            });
        }
    }

    @Emit("select-request")
    selectRequest(requestId: number): number {
        this.popupVisible = false;
        return requestId;
    }
}
