
import Vue from "vue";
import Component from "vue-class-component";
import RequestInput from "@/components/RequestInput";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestActions } from "@/store/modules/marketRequest/actions";
import { Routes } from "@/router/routes";
import FixedPanel from "@/components/FixedPanel";
import BaseButton from "@/components/BaseButton";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { Watch } from "vue-property-decorator";

@Component({
    components: { RequestInput, FixedPanel, BaseButton },
    methods: {
        ...marketRequestModule.mapActions({
            loadRequestById: MarketRequestActions.LoadRequestById,
            exportRequest: MarketRequestActions.Export
        })
    },
    computed: {
        ...marketRequestModule.mapGetters({
            isLoading: MarketRequestGetters.IsLoading
        })
    }
})
export default class ArchivedRequest extends Vue {
    private readonly loadRequestById!: (requestId: number) => Promise<void>;
    protected readonly exportRequest!: () => Promise<void>;

    protected readonly isLoading!: boolean;

    created(): void {
        if (this.requestId) {
            this.loadRequestById(this.requestId);
        } else {
            this.$router.push(Routes.Archive);
        }
    }

    get requestId(): number | null {
        const numberId = Number(this.$route.params.id);
        return !isNaN(numberId) ? numberId : null;
    }

    @Watch("$route.path")
    async onCurrentRouteChange(
        newRoute: string,
        oldRoute: string
    ): Promise<void> {
        if (newRoute !== oldRoute && this.requestId)
            this.loadRequestById(this.requestId);
    }
}
