
import { Tip } from "@/models/Tip";
import { tipModule } from "@/store/modules/tip";
import { TipActions } from "@/store/modules/tip/actions";
import { DxCheckBox } from "devextreme-vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, PropSync } from "vue-property-decorator";

@Component({
    components: { DxCheckBox },
    methods: {
        ...tipModule.mapActions({
            deleteTip: TipActions.DeleteTip
        })
    }
})
export default class RequestTip extends Vue {
    protected doNotShowAgain = false;
    protected isHideTip = false;
    private readonly deleteTip!: (userId: number) => Promise<void>;

    @PropSync("visible", { type: Boolean, required: true })
    public tipVisible!: boolean;

    @Prop({ type: Object, default: false })
    public readonly tip!: Tip;

    hideTip(): void {
        this.isHideTip = true;
    }

    unhideTip(): void {
        this.isHideTip = false;
    }

    closeTip(): void {
        this.tipVisible = false;
        if (this.doNotShowAgain) {
            this.deleteTip(this.tip.tipId);
        }
    }
}
