
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { requestModule } from "@/store/modules/request";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestMutations } from "@/store/modules/request/mutations";
import { Request } from "@/models/request/Request";
import { RequestInfo } from "@/models/request/RequestInfo";
import { LookupEntity } from "@/models/LookupEntity";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { customerModule } from "@/store/modules/customer";
import { CustomerActions } from "@/store/modules/customer/actions";
import {
    DxButton,
    DxDateBox,
    DxDropDownBox,
    DxList,
    DxNumberBox,
    DxScrollView,
    DxSelectBox,
    DxTextArea,
    DxTextBox,
    DxValidationGroup
} from "devextreme-vue";
import { UserActions } from "@/store/modules/user/actions";
import DxValidator, {
    DxRangeRule,
    DxRequiredRule,
    DxStringLengthRule
} from "devextreme-vue/validator";
import { Customer } from "@/models/Customer";
import { CustomerGetters } from "@/store/modules/customer/getters";
import FileUploader from "../FileUploader";
import RequestMarketsForm from "../RequestMarketsForm";
import RequestQuestionList from "../RequestQuestionList";
import RequestConfirmation from "../RequestConfirmationPopup";
import notify from "devextreme/ui/notify";
import { getDefaultEditableFields } from "./getDefaultEditableFields";
import { getErrorMessageFromArray } from "@/services/responseErrorUtils";

@Component({
    components: {
        DxPopup,
        DxSelectBox,
        DxTextBox,
        DxDropDownBox,
        DxList,
        DxDateBox,
        DxNumberBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxRangeRule,
        DxTextArea,
        FileUploader,
        RequestQuestionList,
        RequestMarketsForm,
        DxButton,
        RequestConfirmation,
        DxValidationGroup,
        DxScrollView
    },
    computed: {
        ...requestModule.mapGetters({
            request: RequestGetters.MarketRequest,
            isLoading: RequestGetters.IsLoading,
            info: RequestGetters.Info,
            errors: RequestGetters.Errors
        }),
        ...userModule.mapGetters({
            IKAMs: UserGetters.LookupIkams
        }),
        ...customerModule.mapGetters({
            customers: CustomerGetters.Customers
        })
    },
    methods: {
        ...customerModule.mapActions({
            loadCustomers: CustomerActions.LoadCustomers
        }),
        ...requestModule.mapActions({
            loadRequestById: RequestActions.LoadRequestById,
            saveRequest: RequestActions.SaveRequest,
            updateRequestAndSendMessage:
                RequestActions.UpdateRequestAndSendMessage
        }),
        ...requestModule.mapMutations({
            updateRequest: RequestMutations.UpdateRequest,
            addAttachment: RequestMutations.AddAttachment,
            removeAttachment: RequestMutations.RemoveAttachment
        }),
        ...userModule.mapActions({
            loadIKAMs: UserActions.LoadIKAMs
        })
    }
})
export default class RequestEditPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;
    @Prop({ type: Number, required: true })
    public readonly requestId!: number;
    @Prop({
        type: Array,
        required: false,
        default: () => getDefaultEditableFields()
    })
    public readonly editableFields!: string[];

    private readonly loadRequestById!: (requestId: number) => Promise<void>;
    private readonly updateRequest!: (payload: Partial<Request>) => void;
    private readonly loadIKAMs!: () => Promise<void>;
    private readonly loadCustomers!: () => Promise<void>;
    private readonly updateRequestAndSendMessage!: (
        message: string
    ) => Promise<void>;
    private readonly errors!: string[] | null;

    protected readonly info!: RequestInfo;
    protected readonly IKAMs!: LookupEntity<number>[];
    protected readonly request!: Request;
    protected readonly customers!: Customer[];
    protected showSavePopup = false;

    protected width = 1200;
    protected height = 750;
    protected customerFilter = true;
    private hasErrors = false;

    $refs!: Vue["$refs"] & {
        validationGroup: DxValidationGroup;
    };
    validateInput(): boolean {
        const result = this.$refs.validationGroup.instance?.validate();
        if (!result?.isValid) {
            notify(
                result?.brokenRules?.map((r) => r.message).join(" "),
                "error",
                3000
            );
            return false;
        }
        return true;
    }

    isFieldEditable(fieldName: string): boolean {
        return !this.editableFields.includes(fieldName);
    }
    async saveChanges(message: string): Promise<void> {
        this.hasErrors = false;
        if (!this.validateInput()) {
            return;
        }
        await this.updateRequestAndSendMessage(message);
        if (!this.hasErrors) {
            this.popupVisible = false;
            this.$emit("save-changes");
        }
    }

    mounted(): void {
        this.loadCustomers();
        this.loadIKAMs();
    }

    @Watch("popupVisible")
    visibleChanged(): void {
        if (window.innerHeight < 900) {
            this.height = 600;
        } else {
            this.height = 750;
        }
    }
    @Watch("requestId")
    onRequestIdChange(requestId: number): void {
        this.loadRequestById(requestId);
    }
    @Watch("errors")
    updateErrors(): void {
        if (this.errors?.length) {
            this.hasErrors = true;
            notify(
                `Errors that occurred when saving the request:  ${getErrorMessageFromArray(
                    this.errors
                )}`,
                "error",
                5000
            );
        }
    }
    get currentType(): number | null {
        return this.request.requestTypeId;
    }

    set currentType(value: number | null) {
        if (value) {
            this.updateRequest({ requestTypeId: value });
        }
    }

    get IKAM(): number | null {
        const foundIKAM = this.IKAMs.find((i) => i.id === this.request.ikamId);
        if (foundIKAM) {
            return this.request.ikamId;
        } else {
            return null;
        }
    }

    set IKAM(value: number | null) {
        if (value) this.updateRequest({ ikamId: value });
    }

    get customerDeadline(): Date {
        return this.request.customerDeadlineDate;
    }

    set customerDeadline(value: Date) {
        this.updateRequest({ customerDeadlineDate: value });
    }

    get marketDeadline(): Date {
        return this.request.marketDeadlineDate;
    }

    set marketDeadline(value: Date) {
        this.updateRequest({ marketDeadlineDate: value });
    }

    get filteredCustomers(): Customer[] {
        return this.customers.filter(
            (c) => c.isCustomer === this.customerFilter
        );
    }

    get currentCustomer(): number | null {
        return this.request.customerId ?? null;
    }

    set currentCustomer(value: number | null) {
        const customer = this.customers.find((c) => c.customerId === value);
        if (customer) {
            this.updateRequest({
                customerId: value,
                ikamId: customer.ikamId
            });
        }
    }

    get additionalInformation(): string {
        return this.request.additionalInformation ?? "";
    }

    set additionalInformation(value: string) {
        this.updateRequest({ additionalInformation: value });
    }

    get expectedVolume(): string {
        return this.request.expectedVolume ?? "";
    }
    set expectedVolume(value: string) {
        this.updateRequest({ expectedVolume: value });
    }

    get totalFleet(): string {
        return this.request.totalFleet ?? "";
    }
    set totalFleet(value: string) {
        this.updateRequest({ totalFleet: value });
    }

    get supplyScenarios(): number[] {
        return this.request.supplyScenarios;
    }

    set supplyScenarios(value: number[]) {
        // When the component is loaded, this setter is called with the same value and causes the edit state to change. To avoid this behavior, check the array for equality
        if (value.length === this.supplyScenarios.length) {
            let same = true;
            for (let i = 0; i < value.length; i++) {
                if (value[i] !== this.supplyScenarios[i]) {
                    same = false;
                    break;
                }
            }
            if (same) return;
        }
        this.updateRequest({ supplyScenarios: value });
    }

    get internationalBonus(): string | null {
        return this.request.internationalBonus;
    }

    set internationalBonus(value: string | null) {
        if (value) {
            this.updateRequest({ internationalBonus: value });
        }
    }
}
