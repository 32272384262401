
import Vue from "vue";
import { Component, Emit, Prop, PropSync, Watch } from "vue-property-decorator";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import BaseButton from "@/components/BaseButton";
import ComparisonVehicleItem from "./ComparisonVehicleItem";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { marketRequestCompareModule } from "@/store/modules/marketRequest/compare";
import { MarketRequestCompareGetters } from "@/store/modules/marketRequest/compare/getters";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestActions } from "@/store/modules/marketRequest/actions";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import marketRequestApi from "@/api/marketRequestApi";
import { RequestState } from "@/models/request/Request";
import { Routes } from "@/router/routes";
import notify from "devextreme/ui/notify";
import { SupplyScenario } from "@/models/request/SupplyScenario";
import { MarketRequestCompareMutations } from "@/store/modules/marketRequest/compare/mutations";
import { DxScrollView, DxSelectBox } from "devextreme-vue";

@Component({
    components: {
        DxPopup,
        BaseButton,
        ComparisonVehicleItem,
        DxSelectBox,
        DxToolbarItem,
        DxScrollView
    },
    computed: {
        ...marketRequestCompareModule.mapGetters({
            loading: MarketRequestCompareGetters.IsLoading,
            originalRequestSelectedSupplyScenario:
                MarketRequestCompareGetters.OriginalRequestSelectedSupplyScenario,
            comparedRequestSelectedSupplyScenario:
                MarketRequestCompareGetters.ComparedRequestSelectedSupplyScenario
        }),
        ...marketRequestModule.mapGetters({
            request: MarketRequestGetters.Request
        })
    },
    methods: {
        ...marketRequestModule.mapActions({
            loadRequestById: MarketRequestActions.LoadRequestById
        }),
        ...marketRequestCompareModule.mapMutations({
            updateOriginalRequestSupplyScenario:
                MarketRequestCompareMutations.UpdateOriginalRequestSupplyScenario,
            updateComparedRequestSupplyScenario:
                MarketRequestCompareMutations.UpdateComparedRequestSupplyScenario
        })
    }
})
export default class ComparisonRequestsPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: Object, required: true })
    public readonly settings!: RequestComparisonSettings;

    @Prop({ type: Object, required: true })
    public readonly details!: ComparisonDetailsDTO;

    public linkToCompareRequest = "";

    protected readonly cancelButtonOptions = {
        width: 200,
        text: "Cancel",
        type: "simple",
        stylingMode: "contained",
        onClick: (): void => {
            this.hidePopup();
        }
    };
    protected readonly selectAnotherRequestButtonOptions = {
        width: 200,
        text: "Select another request",
        stylingMode: "contained",
        onClick: (): void => {
            this.selectAnotherRequest();
        },
        elementAttr: {
            class: "dark-theme"
        }
    };
    protected readonly exportComparisonButtonOptions = {
        width: 200,
        text: "Export",
        stylingMode: "contained",
        onClick: (): void => {
            this.exportComparison();
        },
        elementAttr: {
            class: "dark-theme"
        }
    };
    protected readonly saveButtonOptions = {
        width: 200,
        text: "Save",
        type: "default",
        stylingMode: "contained",
        onClick: (): void => {
            this.saveSettings();
        }
    };

    protected readonly loading!: boolean;

    protected readonly originalRequestSelectedSupplyScenario!: SupplyScenario | null;

    protected readonly comparedRequestSelectedSupplyScenario!: SupplyScenario | null;

    @Emit("hidePopup")
    hidePopup(): void {
        this.popupVisible = false;
    }

    private readonly updateOriginalRequestSupplyScenario!: (
        payload: number
    ) => void;

    private readonly updateComparedRequestSupplyScenario!: (
        payload: number
    ) => void;

    get originalRequestSupplyScenarioId(): number | null {
        return (
            this.originalRequestSelectedSupplyScenario?.supplyScenarioId ?? null
        );
    }
    set originalRequestSupplyScenarioId(value: number | null) {
        if (value) {
            this.updateOriginalRequestSupplyScenario(value);
        }
    }
    get comparedRequestSupplyScenarioId(): number | null {
        return (
            this.comparedRequestSelectedSupplyScenario?.supplyScenarioId ?? null
        );
    }
    set comparedRequestSupplyScenarioId(value: number | null) {
        if (value) {
            this.updateComparedRequestSupplyScenario(value);
        }
    }
    get originalHasVehicleDiscount(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountValue;
    }
    get originalHasVehicleDiscountPercent(): boolean {
        return this.details.optionsDto.originalHasVehicleDiscountPercent;
    }
    get originalHasOptionDiscountValue(): boolean {
        return this.details.optionsDto.originalHasOptionDiscountValue;
    }
    get comparedHasVehicleDiscountValue(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountValue;
    }
    get comparedHasVehicleDiscountPercent(): boolean {
        return this.details.optionsDto.comparedHasVehicleDiscountPercent;
    }
    get comparedHasOptionDiscountValue(): boolean {
        return this.details.optionsDto.comparedHasOptionDiscountValue;
    }

    @Emit("save")
    saveSettings(): void {
        // Purposefully empty: Emitting "save" event without altering popup visibility
    }

    selectAnotherRequest(): void {
        this.$emit("select-another-request");
    }

    exportComparison(): void {
        this.$emit("export");
    }

    getSupplyScenarioName(
        supplyScenario: SupplyScenario | null
    ): string | null {
        if (supplyScenario) {
            return supplyScenario.name;
        }
        return null;
    }

    setDefaultSupplyScenarios(): void {
        const originalSupplyScenario = this.settings.originalSupplyScenarioId
            ? this.settings.originalSupplyScenarioId
            : this.details.originalRequestSupplyScenarios[0].supplyScenarioId;
        const comparedSupplyScenario = this.settings.comparedSupplyScenarioId
            ? this.settings.comparedSupplyScenarioId
            : this.details.comparedRequestSupplyScenarios[0].supplyScenarioId;
        this.updateOriginalRequestSupplyScenario(originalSupplyScenario);
        this.updateComparedRequestSupplyScenario(comparedSupplyScenario);
    }

    @Watch("details")
    async onSettingChanged(
        newVal: ComparisonDetailsDTO,
        oldVal: ComparisonDetailsDTO | null
    ): Promise<void> {
        if (newVal.comparedRequestId !== oldVal?.comparedRequestId) {
            try {
                const request = await marketRequestApi.getRequestInfoById(
                    newVal.comparedRequestId
                );
                const isRequestArchived =
                    request.state === RequestState.Completed;
                this.linkToCompareRequest = isRequestArchived
                    ? Routes.ArchivedRequest + newVal.comparedRequestId
                    : Routes.Request + newVal.comparedRequestId;
            } catch (error) {
                notify(`Unhandled error!`, "error", 3000);
            }
        }
    }

    async created(): Promise<void> {
        await this.onSettingChanged(this.details, null);
        this.setDefaultSupplyScenarios();
    }
}
