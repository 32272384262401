
import { DxPopup } from "devextreme-vue/popup";
import Vue from "vue";
import { Component, Emit, Prop, PropSync } from "vue-property-decorator";
import { SupplyScenarioMapping } from "../SupplyScenarioMapping";
import { requestModule } from "@/store/modules/request";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import { RequestGetters } from "@/store/modules/request/getters";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { RequestDetails } from "@/models/request/RequestDetails";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { DxSelectBox } from "devextreme-vue";
import BaseButton from "@/components/BaseButton";

type SupplyScenarioDictionary = { [K: string]: string };

@Component({
    components: {
        DxPopup,
        BaseButton,
        DxSelectBox
    },
    computed: {
        ...marketRequestModule.mapGetters({
            currentRequest: MarketRequestGetters.Request
        }),
        ...requestModule.mapGetters({
            requests: RequestGetters.Requests,
            loading: RequestGetters.IsLoading
        })
    }
})
export default class CopyTermsSupplyScenarioPopup extends Vue {
    protected readonly currentRequest!: RequestDetails;
    protected readonly requests!: RequestListItem[];
    protected readonly loading!: boolean;
    protected selectedSupplyScenarios: SupplyScenarioDictionary = {};

    get copingRequest(): RequestListItem | null {
        return (
            this.requests.find((x) => x.requestId === this.selectedRequestId) ??
            null
        );
    }
    get dropDownMenuItems(): string[] {
        if (this.copingRequest) {
            return [
                "None",
                ...(this.copingRequest?.supplyScenarios as string[])
            ];
        }
        return [];
    }

    mounted(): void {
        this.currentRequest.supplyScenarios.forEach((s) => {
            Vue.set(this.selectedSupplyScenarios, s, "None");
        });
    }

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: Number, required: true })
    public readonly selectedRequestId!: number;

    @Emit("submit")
    copyTerms(): SupplyScenarioMapping {
        this.popupVisible = false;
        return {
            sourceRequestId: this.selectedRequestId,
            supplyScenarioMapping: this.selectedSupplyScenarios
        };
    }

    cancel(): void {
        this.popupVisible = false;
    }
}
