
import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxHeaderFilter,
    DxScrolling,
    DxPaging
} from "devextreme-vue/data-grid";
import { modelModule } from "@/store/modules/model";
import { ModelGetters } from "@/store/modules/model/getters";
import { ModelActions } from "@/store/modules/model/actions";
import { ModelsInfo } from "@/models/ModelsInfo";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestModelFilter } from "@/models/request/RequestVehicleModelFilter";
import { RequestMutations } from "@/store/modules/request/mutations";
import { Request } from "@/models/request/Request";
import { OptionChangedEvent } from "devextreme/ui/button";

type BrandsInfo = ModelsInfo["brands"];
type SeriesInfo = BrandsInfo[0]["series"];
type ModelRangesInfo = SeriesInfo[0]["modelRanges"];

type ModelFilter =
    | "brands"
    | "series"
    | "modelRanges"
    | "fuelTypes"
    | "hybridFlags";

type FilterType = "include" | "exclude";

@Component({
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxHeaderFilter,
        DxScrolling,
        DxPaging
    },
    computed: {
        ...modelModule.mapGetters({
            info: ModelGetters.Info
        }),
        ...requestModule.mapGetters({
            filter: RequestGetters.RequestModelFilter
        })
    },
    methods: {
        ...modelModule.mapActions({
            loadInfo: ModelActions.LoadModelsInfo
        }),
        ...requestModule.mapMutations({
            updateRequest: RequestMutations.UpdateRequest,
            updateFilter: RequestMutations.UpdateModelFilter
        })
    }
})
export default class RequestModelSelector extends Vue {
    protected readonly info!: ModelsInfo | null;
    protected readonly filter!: RequestModelFilter | null;

    private readonly loadInfo!: () => Promise<void>;
    private readonly updateRequest!: (fields: Partial<Request>) => void;
    private readonly updateFilter!: (
        fields: Partial<RequestModelFilter>
    ) => void;

    private readonly filterTypes: {
        [K in ModelFilter]: FilterType;
    } = {
        brands: "include",
        series: "include",
        modelRanges: "include",
        fuelTypes: "include",
        hybridFlags: "include"
    };

    created(): void {
        this.loadInfo();
        if (!this.filter) {
            this.updateRequest({
                requestVehicleModelFilter: {
                    brands: null,
                    series: null,
                    modelRanges: null,
                    fuelTypes: null,
                    hybridFlags: null
                }
            });
        }
    }

    get selectedBrands(): BrandsInfo {
        if (this.info) {
            const info = this.info;
            const brands = this.filter?.brands?.length
                ? this.filter.brands
                : this.brands;
            const selectedBrands: BrandsInfo = [];
            brands.forEach((b) => {
                const brand = info.brands.find((br) => br.brand === b);
                if (brand) selectedBrands.push(brand);
            });
            return selectedBrands;
        }
        return [];
    }

    get selectedSeries(): SeriesInfo {
        if (this.selectedBrands.length) {
            const series = this.filter?.series;
            if (series?.length) {
                return this.selectedBrands
                    .flatMap((b) => b.series)
                    .filter((s) => series?.includes(s.series));
            }
            return this.selectedBrands.flatMap((b) => b.series);
        }
        return [];
    }

    get selectedModelRanges(): ModelRangesInfo {
        if (this.selectedSeries.length) {
            const modelRanges = this.filter?.modelRanges;
            if (modelRanges?.length) {
                return this.selectedSeries
                    .flatMap((s) => s.modelRanges)
                    .filter((m) => modelRanges?.includes(m.modelRange));
            }
            return this.selectedSeries.flatMap((s) => s.modelRanges);
        }
        return [];
    }

    get brands(): string[] {
        if (this.info) {
            return this.info.brands.map((b) => b.brand);
        }
        return [];
    }

    get series(): string[] {
        if (this.selectedBrands.length) {
            const series: string[] = [];
            this.selectedBrands.forEach((brand) => {
                series.push(...brand.series.map((s) => s.series));
            });
            return [...new Set(series)];
        }
        return [];
    }

    get modelRanges(): string[] {
        if (this.selectedSeries.length) {
            const modelRanges: string[] = [];
            this.selectedSeries.forEach((s) => {
                modelRanges.push(...s.modelRanges.map((m) => m.modelRange));
            });
            return [...new Set(modelRanges)];
        }
        return [];
    }

    get fuelTypes(): string[] {
        if (this.selectedModelRanges.length) {
            const fuelTypes: string[] = [];
            this.selectedModelRanges.forEach((m) => {
                fuelTypes.push(...m.fuelTypes);
            });
            return [...new Set(fuelTypes)];
        }
        return [];
    }

    get hybridFlags(): string[] {
        if (this.selectedModelRanges.length) {
            const hybridFlags: string[] = [];
            this.selectedModelRanges.forEach((m) => {
                hybridFlags.push(...m.hybridFlags);
            });
            return [...new Set(hybridFlags)];
        }
        return [];
    }

    get brandValues(): string[] | null {
        return this.getFilterValues(
            this.filter?.brands,
            this.brands,
            this.filterTypes.brands
        );
    }
    set brandValues(value: string[] | null) {
        this.setFilterValues(
            value,
            this.brands,
            this.filterTypes.brands,
            "brands"
        );
    }

    get seriesValues(): string[] | null {
        return this.getFilterValues(
            this.filter?.series,
            this.series,
            this.filterTypes.series
        );
    }
    set seriesValues(value: string[] | null) {
        this.setFilterValues(
            value,
            this.series,
            this.filterTypes.series,
            "series"
        );
    }

    get modelRangeValues(): string[] | null {
        return this.getFilterValues(
            this.filter?.modelRanges,
            this.modelRanges,
            this.filterTypes.modelRanges
        );
    }
    set modelRangeValues(value: string[] | null) {
        this.setFilterValues(
            value,
            this.modelRanges,
            this.filterTypes.modelRanges,
            "modelRanges"
        );
    }

    get fuelTypeValues(): string[] | null {
        return this.getFilterValues(
            this.filter?.fuelTypes,
            this.fuelTypes,
            this.filterTypes.fuelTypes
        );
    }
    set fuelTypeValues(value: string[] | null) {
        this.setFilterValues(
            value,
            this.fuelTypes,
            this.filterTypes.fuelTypes,
            "fuelTypes"
        );
    }

    get hybridFlagValues(): string[] | null {
        return this.getFilterValues(
            this.filter?.hybridFlags,
            this.hybridFlags,
            this.filterTypes.hybridFlags
        );
    }
    set hybridFlagValues(value: string[] | null) {
        this.setFilterValues(
            value,
            this.hybridFlags,
            this.filterTypes.hybridFlags,
            "hybridFlags"
        );
    }

    onOptionChanged(e: OptionChangedEvent, modelFilter: ModelFilter): void {
        // When user check select all checkbox then datagrid changes filter type to another (include <-> exclude).
        // And we need to process this logic, because we can't set only one filter type (we can, but it doesn't work)
        if (e.fullName.includes("filterType")) {
            this.filterTypes[modelFilter] = e.value;
            if (e.value !== e.previousValue) {
                this.updateFilterValues(modelFilter, e.value as FilterType);
            }
        }
    }

    updateFilterValues(modelFilter: ModelFilter, type: FilterType): void {
        if (modelFilter === "brands") {
            this.updateModelFilter(
                this.filter?.brands ?? null,
                type,
                this.brandValues,
                this.brands,
                "brands"
            );
        }
        if (modelFilter === "series") {
            this.updateModelFilter(
                this.filter?.series ?? null,
                type,
                this.seriesValues,
                this.series,
                "series"
            );
        }
        if (modelFilter === "modelRanges") {
            this.updateModelFilter(
                this.filter?.modelRanges ?? null,
                type,
                this.modelRangeValues,
                this.modelRanges,
                "modelRanges"
            );
        }
        if (modelFilter === "fuelTypes") {
            this.updateModelFilter(
                this.filter?.fuelTypes ?? null,
                type,
                this.fuelTypeValues,
                this.fuelTypes,
                "fuelTypes"
            );
        }
        if (modelFilter === "hybridFlags") {
            this.updateModelFilter(
                this.filter?.hybridFlags ?? null,
                type,
                this.hybridFlagValues,
                this.hybridFlags,
                "hybridFlags"
            );
        }
    }

    updateModelFilter(
        filterValues: string[] | null,
        type: FilterType,
        selectedValues: string[] | null,
        values: string[],
        modelFilter: ModelFilter
    ): void {
        let newValues = filterValues;
        if (type === "include") {
            newValues = this.getFilterValues(selectedValues, values, "exclude");
        }
        this.setFilterValues(newValues, values, type, modelFilter);
    }

    setFilterValues(
        selectedValues: string[] | null,
        values: string[],
        type: FilterType,
        modelFilter: ModelFilter
    ): void {
        if (type === "include") {
            this.updateFilter({ [modelFilter]: selectedValues });
        } else if (type === "exclude") {
            if (selectedValues) {
                this.updateFilter({
                    [modelFilter]: values.filter(
                        (v) => !selectedValues.includes(v)
                    )
                });
            } else {
                this.updateFilter({ [modelFilter]: null });
            }
        }
    }

    getFilterValues(
        selectedValues: string[] | null | undefined,
        values: string[],
        type: FilterType
    ): string[] | null {
        if (type === "include") {
            return selectedValues ?? null;
        } else if (selectedValues && type === "exclude") {
            if (selectedValues.length === values.length) {
                return null;
            }
            return values.filter((v) => !selectedValues.includes(v));
        }
        return null;
    }
}
