export const isValidDate = (value: string | null): boolean => {
    if (typeof value !== "string") {
        return false;
    } else if (isNumberString(value)) return false;
    else {
        const date = new Date(value);
        return !isNaN(date.getTime());
    }
};

export const isNumberString = (value: string): boolean => {
    const regex = /^-?\d+$/;
    return regex.test(value);
};
