
import Vue from "vue";
import { Component, Emit, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxButton } from "devextreme-vue/button";
import { DxTextArea } from "devextreme-vue/text-area";
import { LookupEntity } from "@/models/LookupEntity";
import { DxTagBox } from "devextreme-vue/tag-box";
import { ClarificationMessage } from "@/models/ClarificationMessage";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { MarketRequest } from "@/models/request/MarketRequest";
import { isUserInRoles } from "@/services/userUtils";
import { Request } from "@/models/request/Request";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { UserActions } from "@/store/modules/user/actions";
import { User } from "@/models/User";

@Component({
    components: { DxPopup, DxButton, DxTextArea, DxTagBox },
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest,
            selectedRequest: MarketRequestGetters.Request
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...userModule.mapActions({
            loadUsersByMarket: UserActions.LoadUsersByMarket
        })
    }
})
export default class SendMessageToUsersPopup extends Vue {
    protected readonly selectedMarketRequest!: MarketRequest | null;
    protected readonly selectedRequest!: Request | null;
    protected readonly users!: User[] | null;
    private readonly loadUsersByMarket!: (marketId: number) => Promise<void>;

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected message = "";

    protected usersTo = [] as number[];

    confirm(): void {
        this.popupVisible = false;
        if (this.isAdminOrSU)
            this.$emit("confirm", {
                message: this.message,
                selectedUserIds: this.usersTo
            } as ClarificationMessage);
        else
            this.$emit("confirm", {
                message: this.message
            } as ClarificationMessage);
    }

    @Emit()
    cancel(): void {
        this.popupVisible = false;
    }

    get marketUsers(): LookupEntity[] {
        if (this.users)
            return this.users.map((user) => ({
                id: user.id,
                name: `${user.role} ${user.firstName} ${user.lastName}`
            }));
        return [];
    }

    get toUserIds(): number[] {
        return this.usersTo;
    }

    set toUserIds(value: number[]) {
        this.usersTo = value;
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    get title(): string {
        return `New message from TMT on request ${this.selectedRequest?.requestId}`;
    }

    mapMarketUsersIntoUsersTo(): void {
        this.usersTo =
            this.selectedMarketRequest?.usersTo
                .filter((u) => this.marketUsers.some((mu) => mu.id === u.id))
                .map((u) => u.id) ?? [];
    }

    @Watch("selectedMarketRequest")
    async onMarketRequestChanged(newVal: MarketRequest | null): Promise<void> {
        if (newVal?.marketId && this.isAdminOrSU) {
            await this.loadUsersByMarket(newVal?.marketId);
            this.mapMarketUsersIntoUsersTo();
        }
    }

    async created(): Promise<void> {
        if (this.isAdminOrSU && this.selectedMarketRequest?.marketId) {
            await this.loadUsersByMarket(this.selectedMarketRequest.marketId);
            this.mapMarketUsersIntoUsersTo();
        }
    }
}
