
import Vue from "vue";
import Component from "vue-class-component";
import { DxNumberBox } from "devextreme-vue";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxTextArea } from "devextreme-vue/text-area";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { Request } from "@/models/request/Request";
import { RequestActions } from "@/store/modules/request/actions";
import { modelModule } from "@/store/modules/model";
import { RequestMutations } from "@/store/modules/request/mutations";
import { ModelGetters } from "@/store/modules/model/getters";
import { VehicleModel } from "@/models/VehicleModel";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import RequestModelSelector from "@/components/RequestModelSelector";
import RequestFormGrid from "@/components/RequestFormGrid";
import FormConfigSettings from "@/components/FormConfigSettings";
import { RequestInfo } from "@/models/request/RequestInfo";

@Component({
    components: {
        DxTextBox,
        DxTextArea,
        DxNumberBox,
        RequestFormGrid,
        FormConfigSettings,
        RequestModelSelector
    },
    computed: {
        ...requestModule.mapGetters({
            request: RequestGetters.MarketRequest,
            formConfigTemplate: RequestGetters.FormConfigTemplate,
            requestInfo: RequestGetters.Info
        }),
        ...modelModule.mapGetters({
            models: ModelGetters.Models
        })
    },
    methods: {
        ...requestModule.mapMutations({
            updateRequest: RequestMutations.UpdateRequest
        }),
        ...requestModule.mapActions({
            loadRequestFormConfigTemplate:
                RequestActions.LoadRequestFormConfigTemplate
        })
    }
})
export default class RequestSelectFieldsStep extends Vue {
    protected readonly request!: Request;
    private readonly models!: VehicleModel[];
    private readonly formConfigTemplate!: RequestFormConfig | undefined;
    private readonly requestInfo!: RequestInfo;

    private readonly loadRequestFormConfigTemplate!: (
        requestTypeId: number
    ) => Promise<void>;
    private readonly updateRequest!: (fields: Partial<Request>) => void;

    created(): void {
        if (this.request.requestTypeId) {
            this.loadRequestFormConfigTemplate(this.request.requestTypeId).then(
                () => {
                    if (this.formConfigTemplate) {
                        if (!this.request.requestFormConfig) {
                            this.updateRequest({
                                requestFormConfig: {
                                    ...this.formConfigTemplate,
                                    id: 0
                                }
                            });
                        }
                    }
                }
            );
        }
    }

    get validity(): string {
        return this.request.validityOfDiscountIndication ?? "";
    }
    set validity(value: string) {
        this.updateRequest({ validityOfDiscountIndication: value });
    }

    get additionalInformation(): string {
        return this.request.additionalInformation ?? "";
    }
    set additionalInformation(value: string) {
        this.updateRequest({ additionalInformation: value });
    }

    get supplyScenarios(): string[] {
        return this.requestInfo.supplyScenarios
            .filter((s) =>
                this.request.supplyScenarios.includes(s.supplyScenarioId)
            )
            .map((s) => s.name);
    }
}
