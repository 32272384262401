
import Vue from "vue";
import Component from "vue-class-component";
import RequestList from "@/components/RequestList";
import { requestModule } from "@/store/modules/request";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestState } from "@/models/request/Request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import FinalizeRequestConfirmation from "@/components/RequestConfirmationPopup";
import DynamicTitle from "@/components/DynamicTitle";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import RequestEditPopup from "@/components/RequestEditPopup";
import queryService from "@/services/queryService";
import { Route } from "vue-router";
import { Watch } from "vue-property-decorator";
import { RequestListColumnName } from "@/models/request/list/RequestListColumnName";
import { RequestListButtonName } from "@/models/request/list/RequestListButtonName";
import {
    RequestListOptions,
    getDefaultRequestListOptions
} from "@/models/request/list/RequestListOptions";
import {
    standardRequestListFilterValues,
    standardRequestListPaginationValues,
    standardRequestListSortingValues
} from "@/consts/requestListOptionsValues";
import CustomStore from "devextreme/data/custom_store";
import { RequestAdminDetailsUpdateDTO } from "@/models/request/RequestAdminDetailsUpdateDTO";

@Component({
    components: {
        RequestList,
        FinalizeRequestConfirmation,
        DynamicTitle,
        RequestEditPopup
    },
    methods: {
        ...requestModule.mapActions({
            finalizeRequest: RequestActions.FinalizeRequest,
            exportRequest: RequestActions.Export,
            createDataSource: RequestActions.CreateRequestDataSource,
            updateAdminDetails: RequestActions.UpdateRequestAdminDetails
        })
    },
    computed: {
        ...requestModule.mapGetters({
            requests: RequestGetters.Requests,
            isLoading: RequestGetters.IsLoading
        })
    }
})
export default class RequestsInProgress extends Vue {
    protected readonly requests!: RequestListItem[];
    protected readonly isLoading!: boolean;

    private readonly createDataSource!: (payload: {
        state: RequestState;
    }) => Promise<CustomStore>;
    private readonly finalizeRequest!: (payload: {
        requestId: number;
        message: string;
    }) => Promise<void>;
    protected readonly exportRequest!: (payload: number) => Promise<void>;
    protected readonly updateAdminDetails!: (
        payload: RequestAdminDetailsUpdateDTO
    ) => Promise<void>;

    protected finalizeConfirmationPopupVisible = false;
    protected showAttention = false;
    protected selectedRequestId = 0;
    protected editPopupVisible = false;

    protected requestDataSource: CustomStore | null = null;

    protected titleElements: TitleElement[] = [
        { name: "Overview", selected: true, link: Routes.RequestsInProgress }
    ];

    public requestListOptions: RequestListOptions =
        getDefaultRequestListOptions();

    $refs!: {
        requestList: RequestList;
    };

    async created(): Promise<void> {
        this.setRequestListOptionsFromQuery(this.$route.query);
        this.requestDataSource = await this.createDataSource({
            state: RequestState.Active
        });
    }

    get requestListColumns(): Array<RequestListColumnName> {
        const columns: Array<RequestListColumnName> = [
            "requestType",
            "customer",
            "marketDeadline",
            "IKAM",
            "progress",
            "jobDriver",
            "details"
        ];

        // Only add "customerDeadline" if isAdminOrSU is true
        if (this.isAdminOrSU) {
            columns.splice(5, 0, "customerDeadline"); // Insert "customerDeadline" at the correct position
        }

        return columns;
    }

    get requestListButtons(): Array<RequestListButtonName | null> {
        return [
            "view",
            this.isAdminOrSU ? "edit" : null,
            this.isAdminOrSU ? "export" : null
        ];
    }

    viewRequest(requestId: number): void {
        this.$router.push(Routes.Request + requestId);
    }

    editRequest(requestId: number): void {
        this.selectedRequestId = requestId;
        this.editPopupVisible = true;
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    showFinalizeRequestAssertion(requestId: number): void {
        const request = this.requests.find((r) => r.requestId === requestId);
        if (request) {
            this.showAttention =
                request.approvedMarkets !== request.totalMarkets;
            this.selectedRequestId = requestId;
            this.finalizeConfirmationPopupVisible = true;
        }
    }

    finalize(requestId: number, message: string): void {
        this.finalizeRequest({ requestId, message });
    }

    reloadRequests(): void {
        this.$refs.requestList.refresh();
    }

    setRequestListOptionsFromQuery(query: Route["query"]): void {
        this.requestListOptions.filter = queryService.getValues(
            query,
            standardRequestListFilterValues
        );
        this.requestListOptions.pagination = queryService.getValues(
            query,
            standardRequestListPaginationValues
        );
        this.requestListOptions.sorting = queryService.getValues(
            query,
            standardRequestListSortingValues
        );
    }

    async updateAdminDetailsAndReloadRequestList(
        payload: RequestAdminDetailsUpdateDTO
    ): Promise<void> {
        await this.updateAdminDetails(payload);
        if (this.requestDataSource) {
            this.refreshChildData();
        }
    }
    refreshChildData(): void {
        const requestList = this.$refs.requestList as InstanceType<
            typeof RequestList
        >;
        if (requestList) {
            requestList.reloadData();
        }
    }

    @Watch("requestListOptions", { deep: true })
    updateRequestListOptions(): void {
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.filter
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.pagination
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.sorting
        });
    }
}
