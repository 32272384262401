
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MarketModelsSettings from "@/components/MarketModelsSettings";
import FileUploader from "@/components/FileUploader";
import MarketRequestQuestionList from "@/components/MarketRequestQuestionList";
import { RequestDetails } from "@/models/request/RequestDetails";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { MarketRequest } from "@/models/request/MarketRequest";

@Component({
    components: {
        MarketModelsSettings,
        FileUploader,
        MarketRequestQuestionList
    },
    computed: {
        ...marketRequestModule.mapGetters({
            marketRequest: MarketRequestGetters.SelectedMarketRequest
        })
    },
    methods: {
        ...marketRequestModule.mapMutations({
            addAttachment: MarketRequestMutations.AddMarketRequestAttachment,
            removeAttachment:
                MarketRequestMutations.RemoveMarketRequestAttachment
        })
    }
})
export default class MarketRequestSettings extends Vue {
    protected readonly selectedMarketRequest!: RequestDetails;

    @Prop({ type: Boolean, default: false })
    public readonly readonly!: boolean;

    protected readonly marketRequest!: MarketRequest | null;

    protected readonly addAttachment!: (file: File) => void;
    protected readonly removeAttachment!: (file: string | number) => void;
}
