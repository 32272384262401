
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import DxButton from "devextreme-vue/button";
import DxLoadPanel from "devextreme-vue/load-panel";
import { marketRequestCompareModule } from "@/store/modules/marketRequest/compare";
import { MarketRequestCompareGetters } from "@/store/modules/marketRequest/compare/getters";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import {
    formatValidationErrors,
    isValidationError
} from "@/services/responseErrorUtils";
import notify from "devextreme/ui/notify";
import { MarketRequestCompareActions } from "@/store/modules/marketRequest/compare/actions";
import ComparingRequestListPopup from "./ComparingRequestListPopup";
import ComparisonRequestsPopup from "./ComparisonRequestsPopup";
import { MarketRequest } from "@/models/request/MarketRequest";
import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import comparisonService from "@/services/comparisonService";
import { removeVueObserver } from "@/services/vueUtils";
import { RequestDetails } from "@/models/request/RequestDetails";
import { DxTooltip } from "devextreme-vue";

@Component({
    components: {
        DxTooltip,
        DxButton,
        DxLoadPanel,
        ComparingRequestListPopup,
        ComparisonRequestsPopup
    },
    methods: {
        ...marketRequestCompareModule.mapActions({
            loadComparisonSettings:
                MarketRequestCompareActions.LoadComparisonSettingsForMarketRequest,
            getMarketRequestIdByMarket:
                MarketRequestCompareActions.GetMarketRequestIdForRequestByMarket,
            loadComparisonDetails:
                MarketRequestCompareActions.LoadComparisonDetails,
            createComparisonSettings:
                MarketRequestCompareActions.CreateComparisonSettings,
            updateComparisonSettings:
                MarketRequestCompareActions.UpdateComparisonSettings,
            deleteComparisonSettings:
                MarketRequestCompareActions.DeleteComparisonSettings,
            exportComparison: MarketRequestCompareActions.Export
        })
    },
    computed: {
        ...marketRequestCompareModule.mapGetters({
            isLoading: MarketRequestCompareGetters.IsLoading,
            errors: MarketRequestCompareGetters.Errors,
            comparisonSettings: MarketRequestCompareGetters.ComparisonSettings,
            details: MarketRequestCompareGetters.ComparisonDetails
        })
    }
})
export default class CompareRequests extends Vue {
    private readonly loadComparisonSettings!: (
        marketRequestId: number
    ) => Promise<void>;
    private readonly loadComparisonDetails!: (payload: {
        originalMarketRequestId: number;
        comparedMarketRequestId: number;
    }) => Promise<void>;
    private readonly getMarketRequestIdByMarket!: (payload: {
        requestId: number;
        marketId: number;
    }) => Promise<number | null>;
    private readonly createComparisonSettings!: (
        payload: RequestComparisonSettings
    ) => void;
    private readonly updateComparisonSettings!: (
        payload: RequestComparisonSettings
    ) => Promise<void>;
    private readonly deleteComparisonSettings!: (
        payload: number
    ) => Promise<void>;
    private readonly exportComparison!: (
        marketRequestId: number
    ) => Promise<void>;

    @Prop({ type: Object, required: true })
    public readonly marketRequest!: Required<MarketRequest>;

    protected readonly isLoading!: boolean;
    protected readonly errors!: string[] | null;

    protected readonly comparisonSettings!: RequestComparisonSettings | null;
    protected readonly details!: ComparisonDetailsDTO | null;
    protected readonly compareRequest!: RequestDetails | null;

    protected requestListPopupVisible = false;
    protected comparisonRequestPopupVisible = false;

    // It's used a new object without vuex observer to avoid vuex mutation outside store.
    // To avoid endless vuex store changes during editing
    protected currentComparisonSettings: RequestComparisonSettings | null =
        null;

    @Watch("comparisonSettings")
    settingsUpdated(newVal: RequestComparisonSettings | null): void {
        if (newVal) {
            this.currentComparisonSettings = removeVueObserver(newVal);
        } else {
            this.currentComparisonSettings = null;
        }
    }

    async showPopup(): Promise<void> {
        await this.loadComparisonSettings(this.marketRequest.marketRequestId);

        if (this.comparisonSettings) {
            await this.loadComparisonDetails({
                originalMarketRequestId:
                    this.comparisonSettings.originalMarketRequestId,
                comparedMarketRequestId:
                    this.comparisonSettings.comparedMarketRequestId
            });
            this.comparisonRequestPopupVisible = true;
        } else {
            this.requestListPopupVisible = true;
        }
    }

    async selectRequest(requestId: number): Promise<void> {
        const comparedMarketRequestId = await this.getMarketRequestIdByMarket({
            requestId: requestId,
            marketId: this.marketRequest.marketId
        });

        if (!comparedMarketRequestId) {
            notify("Invalid request", "error", 3000);
            return;
        }

        await this.loadComparisonDetails({
            originalMarketRequestId: this.marketRequest.marketRequestId,
            comparedMarketRequestId: comparedMarketRequestId
        });

        if (!this.details) return;

        const settings = comparisonService.createComparisonSettingsFromDetails(
            this.details,
            this.marketRequest.marketRequestId,
            comparedMarketRequestId
        );

        this.createComparisonSettings(settings);
        this.comparisonRequestPopupVisible = true;
    }

    async save(): Promise<void> {
        if (this.currentComparisonSettings) {
            await this.updateComparisonSettings(this.currentComparisonSettings);
        }
    }

    async selectAnotherRequest(): Promise<void> {
        if (this.currentComparisonSettings) {
            await this.deleteComparisonSettings(
                this.currentComparisonSettings.requestComparisonSettingsId
            );
            this.comparisonRequestPopupVisible = false;
            this.requestListPopupVisible = true;
        }
    }

    async exportMarketRequestComparison(): Promise<void> {
        // save comparison before exporting
        if (this.currentComparisonSettings) {
            await this.updateComparisonSettings(this.currentComparisonSettings);
        }
        await this.exportComparison(this.marketRequest.marketRequestId);
    }

    @Watch("errors")
    showErrors(): void {
        if (this.errors?.length) {
            if (isValidationError(this.errors[0])) {
                notify(formatValidationErrors(this.errors), "error", 3000);
            } else {
                notify(this.errors.join(", "), "error", 3000);
            }
        }
    }
}
