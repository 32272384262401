
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DxCheckBox } from "devextreme-vue/check-box";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestActions } from "@/store/modules/marketRequest/actions";
import { TipActions } from "@/store/modules/tip/actions";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import {
    MarketRequest,
    MarketRequestState
} from "@/models/request/MarketRequest";
import { RequestDetails } from "@/models/request/RequestDetails";
import { ValuesData } from "@/models/ValuesData";
import { RequestFormValue } from "@/models/request/RequestFormValue";
import { ModelsFilter } from "@/models/ModelsFilter";
import { VehicleModel } from "@/models/VehicleModel";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import MarketModelsSettings from "@/components/MarketModelsSettings";
import MarketRequestSelector from "@/components/MarketRequestSelector";
import MarketRequestSettings from "@/components/MarketRequestSettings";
import CopyTerms from "@/components/CopyTerms";
import RequestSummary from "@/components/RequestSummary";
import RequestFormGrid from "@/components/RequestFormGrid";
import SingleAccordion from "@/components/SingleAccordion";
import RequestStatusHistory from "@/components/RequestStatusHistory";
import RequestInputControls from "./RequestInputControls.vue";
import CompareRequests from "@/components/CompareRequests";
import { RequestState } from "@/models/request/Request";
import { DxValidationGroup } from "devextreme-vue";
import notify from "devextreme/ui/notify";
import { isUserInRoles } from "@/services/userUtils";
import { CurrentUserChange } from "../RequestFormGrid/CurrentUserChange";
import { RequestCopyTermsDTO } from "@/models/request/RequestCopyTermsDTO";
import ImportRequests from "../ImportRequests/ImportRequests.vue";
import {
    formatValidationErrors,
    getErrorMessageFromArray,
    isValidationError
} from "@/services/responseErrorUtils";
import { Session } from "@/models/Session";
import { accountModule } from "@/store/modules/account";
import { AccountGetters } from "@/store/modules/account/getters";
import { Tip } from "@/models/Tip";
import RequestTip from "../RequestTip";
import { TipGetters } from "@/store/modules/tip/getters";
import { tipModule } from "@/store/modules/tip";
import ImportResultPopup from "../ImportResultPopup/ImportResultPopup.vue";
import { ImportResult } from "@/models/request/ImportResult";
import { SendMarketRequestType } from "@/models/request/MarketRequest";
import { ClarificationMessage } from "@/models/ClarificationMessage";
import queryService from "@/services/queryService";
import marketRequestQueryService from "@/services/marketRequestQueryService";

@Component({
    components: {
        DxCheckBox,
        MarketRequestSettings,
        MarketRequestSelector,
        MarketModelsSettings,
        CopyTerms,
        CompareRequests,
        ImportRequests,
        RequestTip,
        RequestSummary,
        RequestFormGrid,
        SingleAccordion,
        RequestStatusHistory,
        RequestInputControls,
        DxValidationGroup,
        ImportResultPopup
    },
    methods: {
        ...marketRequestModule.mapActions({
            loadValuesData: MarketRequestActions.LoadMarketRequestDataById,
            saveMarketRequest: MarketRequestActions.SaveMarketRequest,
            sendToHQMarketRequest: MarketRequestActions.SendToHQMarketRequest,
            approveMarketRequest: MarketRequestActions.ApproveMarketRequest,
            withdrawApprovalMarketRequest:
                MarketRequestActions.WithdrawApprovalMarketRequest,
            sendMessageToHeadquarters:
                MarketRequestActions.SendMessageToHeadquarters,
            sendMessageToMarketUsers:
                MarketRequestActions.SendMessageToMarketUsers,
            requestUpdateMarketRequest:
                MarketRequestActions.RequestUpdateMarketRequest,
            copyTerms: MarketRequestActions.CopyTerms,
            loadMarketRequestDetails:
                MarketRequestActions.LoadMarketRequestDetails,
            importRequest: MarketRequestActions.Import
        }),
        ...marketRequestModule.mapMutations({
            removeVehicleModels: MarketRequestMutations.RemoveModels,
            addVehicleModels: MarketRequestMutations.AddModels,
            setMarketRequest: MarketRequestMutations.SetSelectedMarketRequest
        }),
        ...tipModule.mapActions({
            loadTipData: TipActions.LoadTips
        })
    },
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest,
            request: MarketRequestGetters.Request,
            valuesData: MarketRequestGetters.ValuesData,
            isLoading: MarketRequestGetters.IsLoading,
            errors: MarketRequestGetters.Errors,
            importResult: MarketRequestGetters.ImportResult
        }),
        ...accountModule.mapGetters({
            session: AccountGetters.Session
        }),
        ...tipModule.mapGetters({
            randomTip: TipGetters.RandomTip
        })
    }
})
export default class RequestInput extends Vue {
    private readonly loadValuesData!: (
        marketRequestId: number
    ) => Promise<void>;
    private readonly saveMarketRequest!: (
        values: RequestFormValue[]
    ) => Promise<void>;
    private readonly removeVehicleModels!: (models: VehicleModel[]) => void;
    private readonly addVehicleModels!: (models: VehicleModel[]) => void;
    private readonly sendToHQMarketRequest!: (message: string) => Promise<void>;
    private readonly loadTipData!: (userId: number) => Promise<void>;
    private readonly approveMarketRequest!: (message: string) => Promise<void>;
    private readonly withdrawApprovalMarketRequest!: (
        message: string
    ) => Promise<void>;
    private readonly sendMessageToHeadquarters!: (
        message: string
    ) => Promise<void>;
    private readonly sendMessageToMarketUsers!: (
        clarificationMessage: ClarificationMessage
    ) => Promise<void>;
    private readonly requestUpdateMarketRequest!: (
        message: string
    ) => Promise<void>;
    private readonly loadMarketRequestDetails!: (
        marketRequestId: number
    ) => Promise<void>;
    private readonly copyTerms!: (
        payload: RequestCopyTermsDTO
    ) => Promise<void>;
    private readonly importRequest!: (payload: {
        marketRequestId: number;
        requestId: number;
        file: File;
    }) => Promise<void>;
    protected readonly setMarketRequest!: (value: MarketRequest) => void;

    @Prop({ type: Boolean, default: false })
    public readonly readonly!: boolean;

    protected readonly isLoading!: boolean;
    protected randomTip!: Tip | null;
    protected readonly request!: RequestDetails;
    protected readonly selectedMarketRequest!: MarketRequest | null;
    protected readonly valuesData!: ValuesData;
    protected readonly errors!: string[] | null;
    private readonly session!: Session | null;
    protected readonly importResult!: ImportResult | null;
    protected tipVisible = true;
    protected importResultPopupVisible = false;

    protected modelsFilter: ModelsFilter = {
        brands: null,
        series: null,
        modelRanges: null,
        fuelTypes: null,
        hybridFlags: null
    };

    private currentUserChange: CurrentUserChange = {
        cell: {
            row: undefined,
            column: undefined
        },
        filters: {
            brands: null,
            series: null,
            modelRanges: null,
            hybridFlags: null,
            fuelTypes: null
        }
    };

    $refs!: Vue["$refs"] & {
        validationGroup: DxValidationGroup;
        requestFormGrid: RequestFormGrid;
    };

    get isMarketUser(): boolean {
        return isUserInRoles(["MSU", "MU"]);
    }

    get isCompletedRequest(): boolean {
        return this.request?.state === RequestState.Completed;
    }
    get statusTextClasses(): string {
        const classes = ["text-selected"];
        if (
            this.selectedMarketRequest?.state === MarketRequestState.Committed
        ) {
            classes.push("warning");
        } else if (
            this.selectedMarketRequest?.state === MarketRequestState.Open
        ) {
            classes.push("secondary");
        } else if (
            this.selectedMarketRequest?.state === MarketRequestState.Approved
        ) {
            classes.push("success");
        } else if (
            this.selectedMarketRequest?.state ===
            MarketRequestState.UpdateRequested
        ) {
            classes.push("danger");
        }
        return classes.join(" ");
    }
    get selectedMarketRequestStatus(): string {
        return this.selectedMarketRequest
            ? `Message history (Current request status: ${this.selectedMarketRequest.state})`
            : "`Message history";
    }

    public openMessageHistory = false;

    private timerId: number | null = null;
    mounted(): void {
        if (!this.readonly) {
            this.timerId = setInterval(this.save, 5 * 60 * 1000); // 5 minutes
        }
        this.openMessageHistory = false;
    }
    beforeDestroy(): void {
        if (!this.readonly && this.timerId) {
            clearInterval(this.timerId);
        }
    }
    created(): void {
        const currentUser = this.session?.User;
        if (currentUser) this.loadTipData(currentUser.id);
    }
    // Create array in component state to reduce vuex store updates to improve performance
    protected formValues: RequestFormValue[] = [];

    @Watch("selectedMarketRequest")
    async onMarketRequestChanged(
        newVal: MarketRequest | null,
        oldVal: MarketRequest | null
    ): Promise<void> {
        const queryMarketRequestId =
            marketRequestQueryService.getMarketRequestIdFromQuery();
        if (
            queryMarketRequestId &&
            queryMarketRequestId !== newVal?.marketRequestId
        ) {
            const marketRequest = this.request.marketRequests.find(
                (mr) => mr.marketRequestId == queryMarketRequestId
            );
            if (marketRequest) {
                this.setMarketRequest(marketRequest);
                await this.loadValuesData(queryMarketRequestId).then(() => {
                    if (this.valuesData.models.length) {
                        this.updateFormValues();
                    }
                });
            } else {
                notify(
                    `The Request doesn't contain MarketRequest with Id: ${queryMarketRequestId}`,
                    "warning",
                    5000
                );
            }
        } else if (newVal?.marketRequestId) {
            this.loadValuesData(newVal.marketRequestId).then(() => {
                if (this.valuesData.models.length) {
                    this.updateFormValues();
                }
            });
        }
        if (this.$route.query.marketRequestStateHistoryId) {
            this.openMessageHistory = true;
            this.scrollToStatus();
        }
        if (newVal?.marketRequestId !== oldVal?.marketRequestId) {
            this.clearUserChange();
        }
    }

    updateFormValues(): void {
        this.formValues = [];
        this.valuesData.models.forEach((m) => {
            const value = this.valuesData.values.find(
                (v) => v.vehicleModelId === m.vehicleModelId
            );
            if (value) {
                // Remove vue observable so we don't mutate vuex store from outside
                // RequestFormValue is a complex object, it also contains additional fields that are dictionaries
                // That's why we need to use JSON methods to remove ALL observable that vuex set
                // If observable objects aren't deleted the application will throw errors in console
                this.formValues.push(JSON.parse(JSON.stringify(value)));
            } else {
                this.formValues.push({
                    vehicleModelId: m.vehicleModelId
                });
            }
        });
    }

    addModels(models: VehicleModel[]): void {
        if (models.length) {
            this.addVehicleModels(models);
        }
    }
    removeModels(models: VehicleModel[]): void {
        if (models.length) {
            this.removeVehicleModels(models);
        }
    }

    getUserChange(): void {
        if (this.$refs.requestFormGrid) {
            const getUserChanges = this.$refs.requestFormGrid.getUserChange();

            this.currentUserChange.filters = { ...getUserChanges.filters };
            this.currentUserChange.cell.row = getUserChanges.cell.row;
            this.currentUserChange.cell.column = getUserChanges.cell.column;
        }
    }

    setUserChange(): void {
        this.$refs.requestFormGrid.setUserChange(this.currentUserChange);
    }

    clearUserChange(): void {
        this.currentUserChange = {
            cell: {
                row: undefined,
                column: undefined
            },
            filters: {
                brands: null,
                series: null,
                modelRanges: null,
                hybridFlags: null,
                fuelTypes: null
            }
        };
    }

    scrollToStatus(): void {
        this.$nextTick(() => {
            const rawStatusId = this.$route.query.marketRequestStateHistoryId;

            let statusId: number | null = null;

            // Check if rawStatusId is an array and take the first element, or directly use the string
            if (Array.isArray(rawStatusId)) {
                statusId =
                    rawStatusId.length > 0 && rawStatusId[0]
                        ? parseInt(rawStatusId[0], 10)
                        : null;
            } else if (typeof rawStatusId === "string") {
                statusId = parseInt(rawStatusId, 10);
            }

            // Ensure statusId is a number before calling the method
            if (statusId && !isNaN(statusId)) {
                const statusHistoryComponent = this.$refs
                    .statusHistoryComponent as InstanceType<
                    typeof RequestStatusHistory
                >;
                if (statusHistoryComponent) {
                    statusHistoryComponent.scrollToStatus(statusId);

                    this.$nextTick(() => {
                        queryService.cleanQuery();
                    });
                }
            }
        });
    }

    async save(): Promise<void> {
        this.getUserChange();
        if (!this.validateInput()) {
            return;
        }

        await this.saveMarketRequest([...this.formValues]);
        await this.loadMarketRequestDetails(
            this.selectedMarketRequest?.marketRequestId ?? 0
        );
        this.updateFormValues();
    }

    async sendMarketRequest(
        sendType: SendMarketRequestType,
        message: string
    ): Promise<void> {
        if (!this.validateInput()) {
            return;
        }

        const action = this.getMarketRequestAction(sendType);
        if (action) {
            await action(message);
        }

        await this.loadMarketRequestDetails(
            this.selectedMarketRequest?.marketRequestId ?? 0
        );
    }

    async sendMessageToUsers(
        clarificationMessage: ClarificationMessage
    ): Promise<void> {
        if (isUserInRoles(["Admin", "SU"]))
            await this.sendMessageToMarketUsers(clarificationMessage);
        else await this.sendMessageToHeadquarters(clarificationMessage.message);

        await this.loadMarketRequestDetails(
            this.selectedMarketRequest?.marketRequestId ?? 0
        );
    }

    private getMarketRequestAction(
        sendType: SendMarketRequestType
    ): ((message: string) => Promise<void>) | null {
        switch (sendType) {
            case SendMarketRequestType.Commit:
                return async (message: string) => {
                    await this.save();
                    await this.sendToHQMarketRequest(message);
                };
            case SendMarketRequestType.Approve:
                return this.approveMarketRequest;
            case SendMarketRequestType.WithdrawApproval:
                return this.withdrawApprovalMarketRequest;
            case SendMarketRequestType.RequestUpdate:
                return this.requestUpdateMarketRequest;
            default:
                return null;
        }
    }

    validateInput(): boolean {
        if (this.isMarketUser) {
            const result = this.$refs.validationGroup.instance?.validate();
            if (!result?.isValid) {
                notify(
                    result?.brokenRules?.map((r) => r.message).join(" "),
                    "error",
                    3000
                );
                return false;
            }
        }
        return true;
    }

    @Watch("errors")
    updateErrors(): void {
        if (this.errors?.length) {
            if (isValidationError(this.errors[0])) {
                notify(
                    `Failed to save: ${formatValidationErrors(this.errors)}`,
                    "error",
                    3000
                );
            } else {
                notify(
                    `Failed to save:  ${getErrorMessageFromArray(this.errors)}`,
                    "error",
                    3000
                );
            }
        }
    }

    importFile(importFile: File): void {
        if (
            this.selectedMarketRequest &&
            this.selectedMarketRequest.marketRequestId
        ) {
            this.importRequest({
                requestId: this.request.requestId,
                marketRequestId: this.selectedMarketRequest.marketRequestId,
                file: importFile
            }).then(() => {
                if (this.valuesData.models.length) {
                    this.updateFormValues();
                }
                this.importResultPopupVisible = true;
            });
        }
    }

    async copyTermsFromRequest(requestSource: {
        sourceRequestId: number;
        supplyScenarioMapping: { [K: string]: string };
    }): Promise<void> {
        if (this.selectedMarketRequest) {
            await this.copyTerms({
                sourceRequestId: requestSource.sourceRequestId,
                destinationRequestId: this.request.requestId,
                marketId: this.selectedMarketRequest.marketId,
                supplyScenarioMapping: requestSource.supplyScenarioMapping
            });
            // Update tables if copying is successful
            if (!this.errors?.length && this.valuesData.models.length) {
                this.updateFormValues();
            }
        }
    }
}
