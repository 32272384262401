import router from "@/router";

function getMarketRequestIdFromQuery(): number | null {
    const marketRequestIdString = Array.isArray(
        router.currentRoute.query.marketRequestId
    )
        ? router.currentRoute.query.marketRequestId[0]
        : router.currentRoute.query.marketRequestId;

    const marketRequestId = marketRequestIdString
        ? parseInt(marketRequestIdString, 10)
        : NaN;

    return isNaN(marketRequestId) ? null : marketRequestId;
}

export default {
    getMarketRequestIdFromQuery
};
