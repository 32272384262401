
import Vue from "vue";
import { Component, Emit, PropSync } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxButton } from "devextreme-vue/button";
import { DxTextArea } from "devextreme-vue/text-area";

@Component({
    components: { DxPopup, DxButton, DxTextArea }
})
export default class MessageSendPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected message = "";

    @Emit()
    confirm(): string {
        this.popupVisible = false;
        return this.message;
    }

    @Emit()
    cancel(): void {
        this.popupVisible = false;
    }
}
