
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { DxButton } from "devextreme-vue/button";
import { isUserInRoles, allowImportValues } from "@/services/userUtils";
import { MarketRequest } from "@/models/request/MarketRequest";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { DxTooltip } from "devextreme-vue";
import notify from "devextreme/ui/notify";
import { DxPopup } from "devextreme-vue/popup";
import { roles } from "@/consts/roles";

@Component({
    components: { DxButton, DxTooltip, DxPopup },
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest,
            isLoading: MarketRequestGetters.IsLoading
        })
    }
})
export default class ImportRequests extends Vue {
    $refs!: {
        fileInput: HTMLInputElement;
    };

    protected fileSource: unknown = null;
    protected readonly selectedMarketRequest!: MarketRequest | null;
    protected isLoading!: boolean;

    importRequest(): void {
        this.$refs.fileInput.click();
    }

    get isAdminOrSUOrMSU(): boolean {
        return isUserInRoles([roles.Admin, roles.SU, roles.MSU, roles.MU]);
    }
    get enableImportButton(): boolean {
        if (!this.selectedMarketRequest) return false;
        return allowImportValues(this.selectedMarketRequest.state);
    }
    valueChanged(e: FileList): void {
        const file = e[0];
        if (file) {
            if (!file.name.endsWith(".xlsx")) {
                notify("You can import only xlsx files", "error", 3000);
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                this.fileSource = e.target?.result ?? null;
            };
            reader.readAsDataURL(file);
            this.$emit("import-file", file);
        }
    }
}
