
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { DxPopup } from "devextreme-vue/popup";
import { DxCheckBox } from "devextreme-vue/check-box";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { ModelGridConfig } from "@/models/ModelGridConfig";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import { DxTooltip } from "devextreme-vue";

@Component({
    components: { DxButton, DxPopup, DxCheckBox, DxTooltip },
    computed: {
        ...marketRequestModule.mapGetters({
            config: MarketRequestGetters.ModelGridConfig
        })
    },
    methods: {
        ...marketRequestModule.mapMutations({
            updateConfig: MarketRequestMutations.UpdateModelGridConfig
        })
    }
})
export default class MarketModelsSettings extends Vue {
    protected readonly config!: ModelGridConfig;

    protected popupVisible = false;

    private readonly updateConfig!: (fields: Partial<ModelGridConfig>) => void;

    showPopup(): void {
        this.popupVisible = true;
    }

    updateField(fieldName: string, value: boolean): void {
        this.updateConfig({ [fieldName]: value });
    }
}
