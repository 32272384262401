import { ModelsFilter } from "@/models/ModelsFilter";
import { VehicleModel } from "@/models/VehicleModel";

function getInfoFromModels(models: VehicleModel[]): ModelsFilter {
    const brands = [...new Set(models.map((m) => m.brand))];
    const series = [...new Set(models.map((m) => m.series))];
    const modelRanges = [...new Set(models.map((m) => m.modelRange))];
    const fuelTypes = [...new Set(models.map((m) => m.fuelType))];
    const hybridFlags = [...new Set(models.map((m) => m.hybridFlag))];
    return {
        brands,
        series,
        modelRanges,
        fuelTypes,
        hybridFlags
    };
}

export default {
    getInfoFromModels
};
