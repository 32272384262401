import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { RequestComparisonVehicleEntry } from "@/models/request/compare/RequestComparisonVehicleEntry";

class ComparisonService {
    public createComparisonSettingsFromDetails(
        details: ComparisonDetailsDTO,
        originalMarketRequestId: number,
        comparedMarketRequestId: number
    ): RequestComparisonSettings {
        const vehicleEntries: RequestComparisonVehicleEntry[] = [];

        this.setOriginalVehicleModels(vehicleEntries, details);
        this.setComparedVehicleModels(vehicleEntries, details);

        const sortedVehicleEntries: RequestComparisonVehicleEntry[] =
            this.sortVehicleEntries(vehicleEntries);

        const originalSupplyScenarioId =
            details.originalRequestSupplyScenarios[0].supplyScenarioId;

        const comparedSupplyScenarioId =
            details.comparedRequestSupplyScenarios[0].supplyScenarioId;

        return {
            requestComparisonSettingsId: 0,
            originalMarketRequestId: originalMarketRequestId,
            comparedMarketRequestId: comparedMarketRequestId,
            originalSupplyScenarioId: originalSupplyScenarioId,
            comparedSupplyScenarioId: comparedSupplyScenarioId,
            vehicleEntries: sortedVehicleEntries
        } as RequestComparisonSettings;
    }

    private sortVehicleEntries(
        vehicleEntries: RequestComparisonVehicleEntry[]
    ) {
        let sortedVehicleEntries = vehicleEntries.filter(
            (e) => e.originalVehicleId && e.comparedVehicleId
        );
        sortedVehicleEntries = sortedVehicleEntries.concat(
            vehicleEntries.filter(
                (e) => e.originalVehicleId && !e.comparedVehicleId
            )
        );
        sortedVehicleEntries = sortedVehicleEntries.concat(
            vehicleEntries.filter(
                (e) => !e.originalVehicleId && e.comparedVehicleId
            )
        );
        return sortedVehicleEntries;
    }

    private setOriginalVehicleModels(
        vehicleEntries: RequestComparisonVehicleEntry[],
        details: ComparisonDetailsDTO
    ): void {
        details.originalRequestModels.forEach((vehicle) => {
            const comparedVehicle = details.comparedRequestModels.find(
                (v) => v.vehicleCode === vehicle.vehicleCode
            );

            const entry: RequestComparisonVehicleEntry = {
                requestComparisonVehicleEntryId: 0,
                requestComparisonSettingsId: 0,
                originalVehicleId: vehicle.vehicleModelId,
                comparedVehicleId: comparedVehicle?.vehicleModelId
            };

            vehicleEntries.push(entry);
        });
    }

    private setComparedVehicleModels(
        vehicleEntries: RequestComparisonVehicleEntry[],
        details: ComparisonDetailsDTO
    ): void {
        details.comparedRequestModels.forEach((comparedVehicle) => {
            if (
                vehicleEntries.find(
                    (e) =>
                        e.comparedVehicleId === comparedVehicle.vehicleModelId
                ) != null
            ) {
                return;
            }
            const entry: RequestComparisonVehicleEntry = {
                requestComparisonVehicleEntryId: 0,
                requestComparisonSettingsId: 0,
                originalVehicleId: null,
                comparedVehicleId: comparedVehicle.vehicleModelId
            };

            vehicleEntries.push(entry);
        });
    }
}

export default new ComparisonService();
