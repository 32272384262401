
import Vue from "vue";
import Component from "vue-class-component";
import { DxTabs } from "devextreme-vue/tabs";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import { RequestDetails } from "@/models/request/RequestDetails";
import { MarketRequest } from "@/models/request/MarketRequest";

@Component({
    components: { DxTabs },
    computed: {
        ...marketRequestModule.mapGetters({
            marketRequest: MarketRequestGetters.SelectedMarketRequest,
            request: MarketRequestGetters.Request
        })
    },
    methods: {
        ...marketRequestModule.mapMutations({
            setMarketRequest: MarketRequestMutations.SetSelectedMarketRequest
        })
    }
})
export default class MarketRequestSelector extends Vue {
    protected readonly setMarketRequest!: (value: MarketRequest) => void;

    protected readonly marketRequest!: MarketRequest | null;
    protected readonly request!: RequestDetails | null;

    get marketRequests(): MarketRequest[] {
        return this.request?.marketRequests ?? [];
    }

    getSelectedClass(marketRequest: MarketRequest): string | null {
        if (this.marketRequest) {
            return marketRequest.marketRequestId ===
                this.marketRequest?.marketRequestId
                ? "selected"
                : "unselected";
        }
        return null;
    }

    getClassForRequestState(state: string): string {
        switch (state.toLowerCase()) {
            case "open":
                return "open";
            case "update requested":
                return "update-requested";
            case "approved":
                return "approved";
            case "committed":
                return "committed";
            default:
                return ""; // Default case if no match is found
        }
    }
}
