import { render, staticRenderFns } from "./RequestTip.html?vue&type=template&id=981bd220&scoped=true&"
import script from "./RequestTip.vue?vue&type=script&lang=ts&"
export * from "./RequestTip.vue?vue&type=script&lang=ts&"
import style0 from "./RequestTip.scss?vue&type=style&index=0&id=981bd220&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "981bd220",
  null
  
)

export default component.exports