
import Vue from "vue";
import Component from "vue-class-component";
import RequestList from "@/components/RequestList";
import { requestModule } from "@/store/modules/request";
import { RequestActions } from "@/store/modules/request/actions";
import { Request, RequestState } from "@/models/request/Request";
import { RequestGetters } from "@/store/modules/request/getters";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import RequestEditPopup from "@/components/RequestEditPopup";
import queryService from "@/services/queryService";
import { Route } from "vue-router";
import { Watch } from "vue-property-decorator";
import { RequestListColumnName } from "@/models/request/list/RequestListColumnName";
import { RequestListButtonName } from "@/models/request/list/RequestListButtonName";
import {
    standardRequestListFilterValues,
    standardRequestListPaginationValues,
    standardRequestListSortingValues
} from "@/consts/requestListOptionsValues";
import {
    RequestListOptions,
    getDefaultRequestListOptions
} from "@/models/request/list/RequestListOptions";
import CustomStore from "devextreme/data/custom_store";

@Component({
    components: { RequestList, RequestEditPopup },
    computed: {
        ...requestModule.mapGetters({
            requests: RequestGetters.Requests,
            request: RequestGetters.MarketRequest,
            loading: RequestGetters.IsLoading
        })
    },
    methods: {
        ...requestModule.mapActions({
            cloneRequest: RequestActions.Clone,
            createDataSource: RequestActions.CreateRequestDataSource
        })
    }
})
export default class Archive extends Vue {
    private readonly cloneRequest!: (state: number) => Promise<void>;
    private readonly createDataSource!: (payload: {
        state: RequestState;
        marketId?: number;
    }) => Promise<CustomStore>;

    protected readonly request!: Request | null;
    protected readonly loading!: boolean;
    protected editPopupVisible = false;
    protected selectedRequestId = 0;

    protected requestDataSource: CustomStore | null = null;

    public requestListOptions: RequestListOptions =
        getDefaultRequestListOptions();

    $refs!: {
        requestList: RequestList;
    };

    async created(): Promise<void> {
        this.setRequestListOptionsFromQuery(this.$route.query);
        this.requestDataSource = await this.createDataSource({
            state: RequestState.Completed
        });
    }

    get requestListColumns(): Array<RequestListColumnName | null> {
        return [
            "requestType",
            "customer",
            "finishedDate",
            "state",
            this.isHQ ? "finishedBy" : null
        ];
    }

    get requestListButtons(): Array<RequestListButtonName | null> {
        return [
            "view",
            this.isAdminOrSU ? "copy" : null,
            this.isAdminOrSU ? "edit" : null
        ];
    }

    viewRequest(requestId: number): void {
        this.$router.push(Routes.ArchivedRequest + requestId);
    }

    editRequest(requestId: number): void {
        this.selectedRequestId = requestId;
        this.editPopupVisible = true;
    }

    copyRequest(requestId: number): void {
        this.cloneRequest(requestId).then(() => {
            if (this.request) {
                this.$router.push(Routes.Draft + this.request.requestId);
            }
        });
    }

    reloadRequests(): void {
        this.$refs.requestList.refresh();
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    get isHQ(): boolean {
        return isUserInRoles(["Admin", "SU", "HV"]);
    }

    setRequestListOptionsFromQuery(query: Route["query"]): void {
        this.requestListOptions.filter = queryService.getValues(
            query,
            standardRequestListFilterValues
        );
        this.requestListOptions.pagination = queryService.getValues(
            query,
            standardRequestListPaginationValues
        );
        this.requestListOptions.sorting = queryService.getValues(
            query,
            standardRequestListSortingValues
        );
    }

    @Watch("requestListOptions", { deep: true })
    updateRequestListOptions(): void {
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.filter
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.pagination
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.sorting
        });
    }
}
