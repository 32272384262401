
import Vue from "vue";
import { Component, Emit, Prop, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxButton } from "devextreme-vue/button";
import { DxCheckBox } from "devextreme-vue/check-box";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { MarketActions } from "@/store/modules/market/actions";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { Market } from "@/models/market/Market";
import { MarketInfo } from "@/models/market/MarketInfo";
import AppIcon from "../AppIcon/AppIcon.vue";

@Component({
    components: { DxPopup, DxButton, DxCheckBox, DxScrollView, AppIcon },
    computed: {
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets,
            marketInfo: MarketGetters.MarketsInfo
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets,
            loadMarketInfo: MarketActions.LoadMarketInfo
        })
    }
})
export default class MarketSelector extends Vue {
    private readonly loadMarkets!: () => Promise<void>;
    private readonly loadMarketInfo!: () => Promise<void>;

    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;
    @Prop({ type: Array, required: true })
    public selectedMarkets!: Market[];

    protected readonly markets!: Market[];
    protected readonly marketInfo!: MarketInfo;

    protected marketRegions = ["C1", "C2", "C4", "C5"];
    protected regionFilter: number[] = [];
    protected currentMarketIds: number[] = [];

    mounted(): void {
        this.loadMarkets();
        this.loadMarketInfo();

        this.currentMarketIds = this.selectedMarkets.map((m) => m.marketId);
    }

    get filteredMarkets(): Market[] {
        if (this.regionFilter.length) {
            return this.markets.filter((m) => {
                return this.regionFilter.includes(m.regionId);
            });
        }
        return this.markets;
    }

    setRegionFilter(region: number, value: boolean): void {
        if (value) {
            this.regionFilter.push(region);
        } else {
            this.regionFilter = this.regionFilter.filter((r) => r !== region);
        }
    }

    setMarket(marketId: number, value: boolean): void {
        if (value) {
            this.currentMarketIds.push(marketId);
        } else {
            this.currentMarketIds = this.currentMarketIds.filter(
                (m) => m !== marketId
            );
        }
    }

    @Emit()
    setSelectedMarkets(): Market[] {
        this.popupVisible = false;
        return this.markets.filter((m) =>
            this.currentMarketIds.includes(m.marketId)
        );
    }

    @Watch("visible")
    onVisibleChanged(val: boolean, oldVal: boolean): void {
        if (val && !oldVal) {
            this.currentMarketIds = this.selectedMarkets.map((m) => m.marketId);
        }
    }
}
