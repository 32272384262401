import { render, staticRenderFns } from "./RequestSummary.html?vue&type=template&id=679a6d43&scoped=true&"
import script from "./RequestSummary.vue?vue&type=script&lang=ts&"
export * from "./RequestSummary.vue?vue&type=script&lang=ts&"
import style0 from "./RequestSummary.scss?vue&type=style&index=0&id=679a6d43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679a6d43",
  null
  
)

export default component.exports