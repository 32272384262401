
import { roles } from "@/consts/roles";
import { MarketRequest } from "@/models/request/MarketRequest";
import { MarketRequestStatus } from "@/models/request/MarketRequestStatus";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest
        })
    }
})
export default class RequestStatusHistory extends Vue {
    private readonly selectedMarketRequest!: MarketRequest | null;

    get statusHistory(): MarketRequestStatus[] {
        return this.selectedMarketRequest?.statusHistory ?? [];
    }

    isMarketComment(status: MarketRequestStatus): boolean {
        return status.user.role == roles.MSU || status.user.role == roles.MU;
    }

    getFormattedDate(value: Date): string {
        const date = new Date(value);
        return `${date.toLocaleDateString()} | ${date.toLocaleTimeString()}`;
    }

    scrollToStatus(statusId: number): void {
        const statusElement = this.$refs[`status-${statusId}`] as HTMLElement[];
        if (statusElement && statusElement[0]) {
            statusElement[0].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }
}
