
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import { MarketRequest } from "@/models/request/MarketRequest";
import { RequestDetails } from "@/models/request/RequestDetails";
import { DxTextBox } from "devextreme-vue/text-box";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { MarketRequestMutations } from "@/store/modules/marketRequest/mutations";
import { QuestionAnswer } from "@/models/QuestionAnswer";

type QuestionInfo = {
    questionId: number;
    answerId: number;
    question: string;
    answer: string;
};

@Component({
    components: { DxTextBox, DxValidator, DxRequiredRule },
    computed: {
        ...marketRequestModule.mapGetters({
            request: MarketRequestGetters.Request,
            currentMarketRequest: MarketRequestGetters.SelectedMarketRequest
        })
    },
    methods: {
        ...marketRequestModule.mapMutations({
            updateMarketRequestAnswer:
                MarketRequestMutations.UpdateMarketRequestAnswer
        })
    }
})
export default class MarketRequestQuestionList extends Vue {
    private readonly request!: RequestDetails | null;
    private readonly currentMarketRequest!: MarketRequest | null;

    @Prop({ type: Boolean, default: false })
    public readonly readonly!: boolean;

    private readonly updateMarketRequestAnswer!: (
        answer: QuestionAnswer
    ) => void;

    get questions(): QuestionInfo[] {
        if (this.request?.questions) {
            return this.request.questions.map((q) => {
                const answer = this.currentMarketRequest?.answers?.find(
                    (a) => a.questionId === q.questionId
                );
                return {
                    questionId: q.questionId,
                    question: q.text,
                    answerId: answer?.id ?? 0,
                    answer: answer?.text ?? ""
                };
            });
        }
        return [];
    }

    updateAnswer(questionId: number, answerId: number, answer: string): void {
        const questionAnswer = this.currentMarketRequest?.answers?.find(
            (q) => q.questionId === questionId
        );
        if (questionAnswer?.text !== answer && answer) {
            this.updateMarketRequestAnswer({
                id: answerId,
                questionId: questionId,
                text: answer
            });
        }
    }

    getQuestionNumber(number: number): string {
        number++;
        if (number < 10) {
            return "0" + number;
        }
        return number.toString();
    }
}
